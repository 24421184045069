<template>
  <div id="background" class="no-animate">
    <BackgroundCanvas ref="backgroundCanvas" :bgEffect="effectList[effectIndex]" />
  </div>
</template>

<script>
import BackgroundCanvas from "./background/BackgroundCanvas";
export default {
  components: {
    BackgroundCanvas,
  },
  data() {
    return {};
  },
  computed: {
    effectIndex() {
      return this.$store.state.bgEffect.bgEffectIndex;
    },
    effectList() {
      return this.$store.state.bgEffect.bgEffectList;
    },
  },
  mounted() {
    document.addEventListener("mousemove", this.$refs.backgroundCanvas.mouseMove);
    document.addEventListener("touchmove", this.$refs.backgroundCanvas.touchMove);
    document.addEventListener("mouseleave", this.$refs.backgroundCanvas.moveEnd);
    document.addEventListener("touchend", this.$refs.backgroundCanvas.moveEnd);
  },
  beforeUnmount() {
    document.removeEventListener("mousemove", this.$refs.backgroundCanvas.mouseMove);
    document.removeEventListener("touchmove", this.$refs.backgroundCanvas.touchMove);
    document.removeEventListener("mouseleave", this.$refs.backgroundCanvas.moveEnd);
    document.removeEventListener("touchend", this.$refs.backgroundCanvas.moveEnd);
  },
};
</script>

<style lang="scss" scoped>
#background {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  overflow: hidden;
}
</style>