export const musicList = [{
  name: "Remember",
  artistList: ["Sensitive", "Bogdan Bondarenko"],
  albumImage: "https://assets.pizzalin.com/images/album/Remember.webp",
  src: "https://assets.pizzalin.com/audio/Sensitive、Bogdan Bondarenko - Remember.mp3",
  durationBeat: 179,
  bpm: 128,
  spectrumColorIndex: 1,
  lamplightAnimationList: [{
      name: "init",
      filter: () => true,
      position: 0 * 60 / 32,
      type: "to",
      duration: 1 * 60 / 32,
      option: ({
        item
      }) => [{
        onStart: item.init
      }],
    },
    {
      name: "0-init",
      filter: ({
        side
      }) => ["topLeft", "topRight", "bottomLeft", "bottomRight"].indexOf(side) > -1,
      position: 1.5 * 60 / 32,
      type: "to",
      duration: 1 * 60 / 32,
      option: () => [{
        type: 0,
        radius: 1536,
        range: 90,
        ease: pos => pos ? 1 : 0
      }],
    },
    {
      name: "0",
      filter: ({
        side
      }) => ["topLeft", "topRight", "bottomLeft", "bottomRight"].indexOf(side) > -1,
      position: 1.5 * 60 / 32,
      type: "fromTo",
      duration: 10 * 60 / 32,
      option: () => [{
          colorA: 0,
        },
        {
          colorA: 0.5,
          ease: pos => pos < 0.05 ? Math.pow(pos * 20, 5) : pos < 0.2 ? Math.pow((0.2 - pos) * 20 / 3, 2) : pos < 0.8 ? 0 : pos < 0.85 ? Math.pow((pos - 0.8) * 20, 5) : Math.pow((1 - pos) * 20 / 3, 2)
        }
      ]
    },
    {
      name: "1-init",
      filter: ({
        index,
        num,
        side
      }) => ["top", "bottom"].indexOf(side) > -1 && [1, 2, 3, 4, 5, 6, 7, 8, 9].some(i => Math.abs(index) === Math.round(num * i / 10)),
      position: 2 * 60 / 32,
      type: "to",
      duration: 1 * 60 / 32,
      option: () => [{
        type: 0,
        colorR: 153,
        colorG: 0,
        colorB: 255,
        radius: 256,
        range: 180,
        ease: pos => pos ? 1 : 0
      }]
    },
    {
      name: "1",
      filter: ({
        index,
        num,
        side
      }) => ["top", "bottom"].indexOf(side) > -1 && [1, 2, 3, 4, 5, 6, 7, 8, 9].some(i => Math.abs(index) === Math.round(num * i / 10)),
      position: 2 * 60 / 32,
      type: "fromTo",
      duration: 4 * 60 / 32,
      option: ({
        index,
        num
      }) => [{
          colorA: 0,
        },
        {
          colorA: 0.5,
          repeat: 3,
          ease: pos => {
            let newPos;
            let posArr = [];
            let posData = [{
                pos: 1,
                posArr: [8]
              },
              {
                pos: 2,
                posArr: [4, 12]
              },
              {
                pos: 3,
                posArr: [0]
              },
              {
                pos: 4,
                posArr: [9]
              },
              {
                pos: 5,
                posArr: [5, 7, 13]
              },
              {
                pos: 6,
                posArr: [1]
              },
              {
                pos: 7,
                posArr: [10]
              },
              {
                pos: 8,
                posArr: [6, 14]
              },
              {
                pos: 9,
                posArr: [2]
              },
            ]
            posData.forEach(item => {
              if (Math.abs(index) === Math.round(num * item.pos / 10)) {
                posArr.push(...item.posArr);
              }
            })
            posArr.sort((a, b) => b - a).map(item => item / 16).some(item => {
              if (pos > item) {
                newPos = Math.max((item + 2 / 16 - pos) * 8, 0)
                return true;
              }
            }) || (newPos = 0);
            return newPos;
          }
        }
      ]
    },
    {
      name: "2-init",
      filter: ({
        index,
        num,
        side
      }) => ["left", "right"].indexOf(side) > -1 && [1, 3, 5, 7, 9, 11].some(i => Math.abs(index) === Math.round(num * i / 13)),
      position: 2 * 60 / 32,
      type: "to",
      duration: 1 * 60 / 32,
      option: () => [{
        type: 0,
        colorR: 0,
        colorG: 153,
        colorB: 255,
        radius: 256,
        range: 180,
        ease: pos => pos ? 1 : 0
      }]
    },
    {
      name: "2",
      filter: ({
        index,
        num,
        side
      }) => ["left", "right"].indexOf(side) > -1 && [1, 3, 5, 7, 9, 11].some(i => Math.abs(index) === Math.round(num * i / 13)),
      position: 2 * 60 / 32,
      type: "fromTo",
      duration: 16 * 60 / 32,
      option: ({
        index,
        num
      }) => [{
          colorA: 0,
        },
        {
          colorA: 0.5,
          ease: pos => {
            let newPos;
            let posArr = [];
            let posData = [{
                pos: -11,
                posArr: [39, 70]
              },
              {
                pos: -9,
                posArr: [67, 69, 75, 76.5, 78]
              },
              {
                pos: -7,
                posArr: [31, 35, 36, 38, 43, 44, 46, 52, 54, 63, 68, 71, 76, 77]
              },
              {
                pos: -5,
                posArr: [14, 22, 30, 35.5, 37, 43.5, 45, 53, 62, 79, 83]
              },
              {
                pos: -3,
                posArr: [12, 20, 29, 47, 61, 84.5, 86]
              },
              {
                pos: -1,
                posArr: [13, 21, 28, 60, 84, 85]
              },
              {
                pos: 1,
                posArr: [7, 55, 87, 103, 111, 119, 127]
              },
              {
                pos: 3,
                posArr: [6, 102, 110, 118, 126]
              },
              {
                pos: 5,
                posArr: [0, 5, 8, 15, 92, 96, 101, 104, 109, 117, 125]
              },
              {
                pos: 7,
                posArr: [23, 91, 93, 95]
              },
              {
                pos: 9,
                posArr: [94, 112]
              },
              {
                pos: 11,
                posArr: [120]
              },
            ]
            posData.forEach(item => {
              if (index === Math.round(num * item.pos / 13)) {
                posArr.push(...item.posArr);
              }
            })
            posArr.sort((a, b) => b - a).map(item => item / 128).some(item => {
              if (pos > item) {
                newPos = Math.max((item + 4 / 128 - pos) * 32, 0)
                return true;
              }
            }) || (newPos = 0);
            return newPos;
          }
        }
      ]
    },
    {
      name: "3-init",
      filter: ({
        side
      }) => ["topLeft", "topRight", "bottomLeft", "bottomRight"].indexOf(side) > -1,
      position: 10 * 60 / 32,
      type: "to",
      duration: 1 * 60 / 32,
      option: () => [{
        type: 0,
        radius: 1024,
        ease: pos => pos ? 1 : 0
      }]
    },
    {
      name: "3",
      filter: ({
        side
      }) => ["topLeft", "topRight", "bottomLeft", "bottomRight"].indexOf(side) > -1,
      position: 10 * 60 / 32,
      type: "fromTo",
      duration: 0.25 * 60 / 32,
      option: () => [{
          colorR: 102,
          colorG: 0,
          colorB: 255,
          range: 60,
        },
        {
          colorR: 255,
          colorG: 255,
          colorB: 255,
          range: 90,
          repeat: 30,
          ease: pos => pos < 0.5 ? (pos + 0.25) * 4 / 3 : pos < 0.75 ? Math.pow((0.75 - pos) * 4, 2) : (pos - 0.75) * 4 / 3
        }
      ]
    },
    {
      name: "3-fadeIn",
      filter: ({
        side
      }) => ["topLeft", "topRight", "bottomLeft", "bottomRight"].indexOf(side) > -1,
      position: 10 * 60 / 32,
      type: "from",
      duration: 7.75 * 60 / 32,
      option: () => [{
        colorA: 0,
        ease: pos => Math.pow(pos, 2)
      }]
    },
    {
      name: "3-flash",
      filter: ({
        side
      }) => ["topLeft", "topRight", "bottomLeft", "bottomRight"].indexOf(side) > -1,
      position: 17.75 * 60 / 32,
      type: "fromTo",
      duration: 0.25 * 60 / 32,
      option: () => [{
          colorR: 102,
          colorG: 0,
          colorB: 255,
          colorA: 0.5,
          range: 60,
        },
        {
          colorR: 255,
          colorG: 255,
          colorB: 255,
          colorA: 0.75,
          range: 90,
          ease: pos => pos < 0.5 ? (pos + 0.25) * 4 / 3 : pos < 0.75 ? Math.pow((0.75 - pos) * 4, 2) : Math.pow((pos - 0.75) * 4, 2)
        }
      ]
    },
    {
      name: "3-fadeOut",
      filter: ({
        side
      }) => ["topLeft", "topRight", "bottomLeft", "bottomRight"].indexOf(side) > -1,
      position: 18 * 60 / 32,
      type: "to",
      duration: 2 * 60 / 32,
      option: () => [{
        colorA: 0,
        ease: pos => 1 - Math.pow((1 - pos), 2)
      }]
    },
    {
      name: "4-init",
      filter: ({
        index,
        side
      }) => side === "top" && index % 2 === 0,
      position: 18 * 60 / 32,
      type: "to",
      duration: 1 * 60 / 32,
      option: () => [{
        type: 1,
        width: 40,
        ease: pos => pos ? 1 : 0
      }]
    },
    {
      name: "4",
      filter: ({
        index,
        side
      }) => side === "top" && index % 2 === 0,
      position: 18 * 60 / 32,
      type: "fromTo",
      duration: 0.5 * 60 / 32,
      option: ({
        index
      }) => [{
          angle: index % 4 === 0 ? 105 : 75,
        },
        {
          angle: index % 4 === 0 ? 75 : 105,
          yoyo: true,
          repeat: 47,
          ease: pos => pos < 0.5 ? Math.pow(pos * 2, 2) / 2 : 1 - Math.pow((1 - pos) * 2, 2) / 2
        }
      ]
    },
    {
      name: "4-color",
      filter: ({
        index,
        side
      }) => side === "top" && index % 2 === 0,
      position: 18 * 60 / 32,
      type: "fromTo",
      duration: 0.5 * 60 / 32,
      option: ({
        index
      }) => [{
          colorR: 255,
          colorG: 255,
          colorB: 255,
          colorA: 0.1,
        },
        {
          colorR: 153,
          colorG: 0,
          colorB: 255,
          colorA: 0.25,
          repeat: 45,
          ease: pos => {
            let n = Math.abs(index) % 4 / 4;
            return pos > n ? Math.max((n + 0.5 - pos) * 2, 0) : 0;
          }
        }
      ]
    },
    {
      name: "4-fadeOut",
      filter: ({
        index,
        side
      }) => side === "top" && index % 2 === 0,
      position: 41 * 60 / 32,
      type: "to",
      duration: 1 * 60 / 32,
      option: () => [{
        colorA: 0,
      }]
    },
    {
      name: "5-init",
      filter: ({
        side
      }) => ["left", "right"].indexOf(side) > -1,
      position: 18 * 60 / 32,
      type: "to",
      duration: 1 * 60 / 32,
      option: () => [{
        type: 1,
        colorR: 0,
        colorG: 204,
        colorB: 255,
        colorA: 0.1,
        width: 24,
        ease: pos => pos ? 1 : 0
      }]
    },
    {
      name: "5",
      filter: ({
        side
      }) => ["left", "right"].indexOf(side) > -1,
      position: 18 * 60 / 32,
      type: "fromTo",
      duration: 4 * 60 / 32,
      option: ({
        index,
        side
      }) => [{
          angle: 90,
        },
        {
          angle: side === "left" ? -270 : 450,
          repeat: 5,
          ease: pos => {
            return (pos + 1 - (index % 20 + 20) % 20 / 20) % 1;
          }
        }
      ]
    },
    {
      name: "6-init",
      filter: ({
        index,
        side
      }) => side === "top" && index % 2 !== 0,
      position: 22 * 60 / 32,
      type: "to",
      duration: 1 * 60 / 32,
      option: () => [{
        type: 0,
        radius: 1024,
        range: 180,
        ease: pos => pos ? 1 : 0
      }]
    },
    {
      name: "6",
      filter: ({
        index,
        side
      }) => side === "top" && index % 2 !== 0,
      position: 22 * 60 / 32,
      type: "fromTo",
      duration: 0.5 * 60 / 32,
      option: () => [{
        colorR: 255,
        colorG: 255,
        colorB: 255,
        colorA: 0,
      }, {
        colorR: 51,
        colorG: 0,
        colorB: 255,
        colorA: 0.1,
        repeat: 39,
        ease: pos => pos < 0.5 ? Math.pow(pos, 5) : Math.pow((1 - pos) * 2, 2)
      }]
    },
    {
      name: "7-init",
      filter: ({
        index,
        num,
        side
      }) => side === "bottom" && [1, 2, 3, 4].some(i => Math.abs(index) === Math.round(num * i / 5)),
      position: 26 * 60 / 32,
      type: "to",
      duration: 1 * 60 / 32,
      option: () => [{
        type: 0,
        angle: 270,
        colorR: 255,
        colorG: 0,
        colorB: 204,
        radius: 1024,
        ease: pos => pos ? 1 : 0
      }]
    },
    {
      name: "7",
      filter: ({
        index,
        num,
        side
      }) => side === "bottom" && [1, 2, 3, 4].some(i => Math.abs(index) === Math.round(num * i / 5)),
      position: 26 * 60 / 32,
      type: "fromTo",
      duration: 0.5 * 60 / 32,
      option: ({
        index,
        num
      }) => [{
        colorA: 0,
        range: 15,
      }, {
        colorA: 0.5,
        range: 30,
        repeat: 15,
        ease: pos => {
          let newPos;
          [1, 4, 2, 3].some((item, i) => {
            if (Math.abs(index) === Math.round(num * item / 5)) {
              newPos = pos > i * 0.25 ? Math.max(((i + 1) * 0.25 - pos), 0) * 4 : 0;
              return true;
            }
          }) || (newPos = 0);
          return newPos;
        }
      }]
    },
    {
      name: "7-double",
      filter: ({
        index,
        num,
        side
      }) => side === "bottom" && [1, 2, 3, 4].some(i => Math.abs(index) === Math.round(num * i / 5)),
      position: 34 * 60 / 32,
      type: "fromTo",
      duration: 0.5 * 60 / 32,
      option: ({
        index,
        num
      }) => [{
        colorA: 0,
        range: 15,
      }, {
        colorA: 0.5,
        range: 30,
        repeat: 15,
        ease: pos => {
          let newPos;
          [-1, 1, -4, 4, -2, 2, -3, 3].some((item, i) => {
            if (index === Math.round(num * item / 5)) {
              newPos = pos > i * 0.125 ? Math.max(((i + 2) * 0.125 - pos), 0) * 4 : 0;
              return true;
            }
          }) || (newPos = 0);
          return newPos;
        }
      }]
    },
    {
      name: "8-init",
      filter: ({
        side
      }) => ["topLeft", "topRight", "bottomLeft", "bottomRight"].indexOf(side) > -1,
      position: 41 * 60 / 32,
      type: "to",
      duration: 1 * 60 / 32,
      option: () => [{
        type: 0,
        radius: 1024,
        ease: pos => pos ? 1 : 0
      }]
    },
    {
      name: "8",
      filter: ({
        side
      }) => ["topLeft", "topRight", "bottomLeft", "bottomRight"].indexOf(side) > -1,
      position: 41 * 60 / 32,
      type: "fromTo",
      duration: 0.25 * 60 / 32,
      option: () => [{
          colorR: 102,
          colorG: 0,
          colorB: 255,
          range: 60,
        },
        {
          colorR: 255,
          colorG: 255,
          colorB: 255,
          range: 90,
          repeat: 2,
          ease: pos => pos < 0.5 ? (pos + 0.25) * 4 / 3 : pos < 0.75 ? Math.pow((0.75 - pos) * 4, 2) : (pos - 0.75) * 4 / 3
        }
      ]
    },
    {
      name: "8-fadeIn",
      filter: ({
        side
      }) => ["topLeft", "topRight", "bottomLeft", "bottomRight"].indexOf(side) > -1,
      position: 41 * 60 / 32,
      type: "from",
      duration: 0.75 * 60 / 32,
      option: () => [{
        colorA: 0
      }]
    },
    {
      name: "8-flash",
      filter: ({
        side
      }) => ["topLeft", "topRight", "bottomLeft", "bottomRight"].indexOf(side) > -1,
      position: 41.75 * 60 / 32,
      type: "fromTo",
      duration: 0.25 * 60 / 32,
      option: () => [{
          colorR: 102,
          colorG: 0,
          colorB: 255,
          colorA: 0.5,
          range: 60,
        },
        {
          colorR: 255,
          colorG: 255,
          colorB: 255,
          colorA: 0.75,
          range: 90,
          ease: pos => pos < 0.5 ? (pos + 0.25) * 4 / 3 : pos < 0.75 ? Math.pow((0.75 - pos) * 4, 2) : Math.pow((pos - 0.75) * 4, 2)
        }
      ]
    },
    {
      name: "8-fadeOut",
      filter: ({
        side
      }) => ["topLeft", "topRight", "bottomLeft", "bottomRight"].indexOf(side) > -1,
      position: 42 * 60 / 32,
      type: "to",
      duration: 2 * 60 / 32,
      option: () => [{
        colorA: 0,
        ease: pos => 1 - Math.pow((1 - pos), 2)
      }]
    },
    {
      name: "9-init",
      filter: ({
        side
      }) => ["topLeft", "topRight", "bottomLeft", "bottomRight"].indexOf(side) > -1,
      position: 42 * 60 / 32,
      type: "to",
      duration: 2 * 60 / 32,
      option: () => [{
        colorA: 0,
        ease: pos => 1 - Math.pow((1 - pos), 2)
      }]
    },
  ]
}, {
  name: "Legacy",
  artistList: ["Tobu"],
  albumImage: "https://assets.pizzalin.com/images/album/Legacy.webp",
  src: "https://assets.pizzalin.com/audio/Tobu - Legacy.mp3",
  durationBeat: 77,
  bpm: 100,
  spectrumColorIndex: 2,
}, {
  name: "Aurora",
  artistList: ["Janji", "T.R"],
  albumImage: "https://assets.pizzalin.com/images/album/Aurora.webp",
  src: "https://assets.pizzalin.com/audio/Janji、T.R. - Aurora.mp3",
  durationBeat: 115,
  bpm: 105,
  spectrumColorIndex: 3,
}, {
  name: "Surpass",
  artistList: ["Resonance"],
  albumImage: "https://assets.pizzalin.com/images/album/Surpass.webp",
  src: "https://assets.pizzalin.com/audio/Resonance - Surpass.mp3",
  durationBeat: 140,
  bpm: 130,
  spectrumColorIndex: 4,
}, {
  name: "Access",
  artistList: ["Martin Garrix"],
  albumImage: "https://assets.pizzalin.com/images/album/Access.webp",
  src: "https://assets.pizzalin.com/audio/Martin Garrix - Access.mp3",
  durationBeat: 104,
  bpm: 128,
  spectrumColorIndex: 5,
}, {
  name: "Move",
  artistList: ["Axero", "Itro"],
  albumImage: "https://assets.pizzalin.com/images/album/Move.webp",
  src: "https://assets.pizzalin.com/audio/Axero、Itro - Move.mp3",
  durationBeat: 163,
  bpm: 128,
  spectrumColorIndex: 6,
}];