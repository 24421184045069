import {
  createRouter,
  createWebHistory
} from "vue-router"

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [{
    path: "/",
    name: "Home",
    component: () => import("../views/ViewHome"),
  }, {
    path: "/works",
    name: "Works",
    component: () => import("../views/ViewWorks"),
    children: [{
      path: "scroll-code",
      name: "WorksScrollCode",
      component: () => import("../views/works/ViewScrollCode"),
    }, {
      path: "floating-hello-world",
      name: "WorksFloatingHelloWorld",
      component: () => import("../views/works/ViewFloatingHelloWorld"),
    }, {
      path: "music-spectrum",
      name: "WorksMusicSpectrum",
      component: () => import("../views/works/ViewMusicSpectrum"),
    }, {
      path: "code-rain",
      name: "WorksCodeRain",
      component: () => import("../views/works/ViewCodeRain"),
    }, {
      path: "music-player",
      name: "WorksMusicPlayer",
      component: () => import("../views/works/ViewMusicPlayer"),
    }, {
      path: "color-selector",
      name: "WorksColorSelector",
      component: () => import("../views/works/ViewColorSelector"),
    }, {
      path: "gradient-editor",
      name: "WorksGradientEditor",
      component: () => import("../views/works/ViewGradientEditor"),
    }, {
      path: "gravity-ball",
      name: "WorksGravityBall",
      component: () => import("../views/works/ViewGravityBall"),
    }, {
      path: "gluttonous-snake",
      name: "WorksGluttonousSnake",
      component: () => import("../views/works/ViewGluttonousSnake"),
    }],
  }, {
    path: "/about",
    name: "About",
    component: () => import("../views/ViewAbout"),
  }],
});

export default router;