export default {
  namespaced: true,
  state: {
    Alt: false,
    LAlt: false,
    RAlt: false,
    Ctrl: false,
    LCtrl: false,
    RCtrl: false,
    Shift: false,
    LShift: false,
    RShift: false,
  },
  mutations: {
    change(state, {
      key,
      value, 
    }) {
      if (state[key] === undefined) return;
      state[key] = value;
    },
  },
  actions: {
    create(context) {
      window.addEventListener("keydown", (event) => {
        context.dispatch("change", {
          code: event.code,
          value: true,
        });
      });
      window.addEventListener("keyup", (event) => {
        context.dispatch("change", {
          code: event.code,
          value: false,
        });
      });
    },
    change(context, {
      code,
      value,
    }) {
      switch (code) {
        case "AltLeft":
          context.state.RAlt || context.commit("change", {
            key: "Alt",
            value,
          });
          context.commit("change", {
            key: "LAlt",
            value,
          });
          break;
        case "AltRight":
          context.state.LAlt || context.commit("change", {
            key: "Alt",
            value,
          });
          context.commit("change", {
            key: "RAlt",
            value,
          });
          break;
        case "ControlLeft":
          context.state.RCtrl || context.commit("change", {
            key: "Ctrl",
            value,
          });
          context.commit("change", {
            key: "LCtrl",
            value,
          });
          break;
        case "ControlRight":
          context.state.LCtrl || context.commit("change", {
            key: "Ctrl",
            value,
          });
          context.commit("change", {
            key: "RCtrl",
            value,
          });
          break;
        case "ShiftLeft":
          context.state.RShift || context.commit("change", {
            key: "Shift",
            value,
          });
          context.commit("change", {
            key: "LShift",
            value,
          });
          break;
        case "ShiftRight":
          context.state.LShift || context.commit("change", {
            key: "Shift",
            value,
          });
          context.commit("change", {
            key: "RShift",
            value,
          });
          break;
        default:
          break;
      }
    },
  },
  modules: {},
};