<template>
  <div class="pui-button" :class="{ 'pui-button-active': value }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    value: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.pui-button {
  background-repeat: no-repeat;
  border-color: rgba(var(--color0-dark2), 0.8);
  border-style: solid;
  color: rgba(var(--color0-light4), 1);
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  @media (min-width: 768px) {
    height: 40px;
    padding: 0 20px;
    border-width: 2px;
    border-radius: 10px;
    text-shadow: 0 0 10px #000;
  }
  @media (max-width: 767px) {
    height: 8vw;
    padding: 0 4vw;
    border-width: 0.4vw;
    border-radius: 2vw;
    text-shadow: 0 0 2vw #000;
  }
  &:hover {
    opacity: 0.8;
  }
  & + .pui-button {
    @media (min-width: 768px) {
      margin-left: 8px;
    }
    @media (max-width: 767px) {
      margin-left: 1.6vw;
    }
  }
  &.pui-button-active {
    border-color: rgba(var(--color0), 0.8);
    background-color: rgba(var(--color2-dark4), 0.5);
    color: rgba(var(--color0-light2), 1);
    @media (min-width: 768px) {
      box-shadow: 0 0 5px rgba(var(--color0), 0.8);
    }
    @media (max-width: 767px) {
      box-shadow: 0 0 1vw rgba(var(--color0), 0.8);
    }
  }
}
</style>