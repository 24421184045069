<template>
  <div
    id="header"
    :style="{
      backgroundImage: `linear-gradient(to right, rgba(var(--color3-dark3), ${backgroundAlpha}), rgba(var(--color1-dark4), ${backgroundAlpha}))`,
    }"
  >
    <div class="container">
      <!-- 左侧logo -->
      <div class="header-logo">
        <a class="logo" href="/"><Logo /></a>
      </div>
      <!-- 导航栏 -->
      <div class="header-nav">
        <template v-for="item in navList" :key="item.name">
          <a v-if="item.name == $route.name" class="nav-item active">{{
            item.title
          }}</a>
          <router-link
            v-else
            class="nav-item"
            :to="{ name: item.name }"
            replace
            >{{ item.title }}</router-link
          >
        </template>
      </div>
      <!-- 右侧头像 -->
      <div class="header-portrait">
        <img
          :class="{ 'settings-opened': settingsOpened }"
          src="https://assets.pizzalin.com/images/pizza.webp"
          alt=""
          @click="settingsOpened = true"
        />
        <!-- <div class="popup" :class="{ hidden: settingsOpened }">
          <div class="bgm-box">
            <div
              class="bgm-btn _fcc"
              @click="$store.dispatch('musicPlayer/togglePaused')"
            >
              <Icon name="music-paused" :state="paused" />
            </div>
            <div class="bgm-info">
              <div class="name">{{ musicList[musicIndex]?.name || "" }}</div>
              <div class="artist">
                {{ musicList[musicIndex]?.artistList.join("、") || "" }}
              </div>
            </div>
          </div>
        </div> -->
        <div class="settings" :class="{ opened: settingsOpened }">
          <div class="settings-header">
            <div class="close-btn" @click.stop="settingsOpened = false">
              <Icon name="close" />
            </div>
          </div>
          <div class="settings-body" :class="{ show: settingsOpened }">
            <SettingsPanel ref="settings" />
          </div>
          <Icon name="settings" @click="settingsOpened = true" />
        </div>
      </div>
      <!-- 移动端菜单 -->
      <div class="header-menu" @click="menuChecked = !menuChecked">
        <div class="menu-btn" :class="{ hide: settingsOpened }">
          <Icon name="expand-menu" :state="menuChecked ? 'right' : 'normal'" />
        </div>
        <div class="mask" :class="{ show: menuChecked }"></div>
        <div
          class="menu-list"
          :class="{ show: menuChecked && !settingsOpened }"
        >
          <template v-for="item in navList" :key="item.name">
            <a v-if="item.name == $route.name" class="menu-item active">{{
              item.title
            }}</a>
            <router-link v-else class="menu-item" :to="{ name: item.name }">{{
              item.title
            }}</router-link>
          </template>
        </div>
        <div
          class="settings-btn"
          :class="{ show: menuChecked, open: settingsOpened }"
        >
          <Icon
            name="settings"
            @click.stop="settingsOpened = !settingsOpened"
          />
        </div>
      </div>
      <!-- 移动端音乐按钮 -->
      <!-- <div
        class="bgm-icon"
        :style="{ animationPlayState: paused ? 'paused' : 'running' }"
        @click="$store.dispatch('musicPlayer/togglePaused')"
      >
        <Icon name="music" :state="menuChecked" />
      </div> -->
    </div>
  </div>
</template>

<script>
import { musicList } from "@/assets/js/musicList";

import Logo from "./svg/SvgLogo";
import Icon from "./svg/SvgIcon";
import SettingsPanel from "./header/SettingsPanel";
export default {
  components: {
    Logo,
    Icon,
    SettingsPanel,
  },
  data() {
    return {
      backgroundAlpha: 0,
      navList: [
        {
          title: "首页",
          name: "Home",
        },
        {
          title: "项目/作品",
          name: "Works",
        },
        {
          title: "联系我",
          name: "About",
        },
      ],
      menuChecked: false,
      settingsOpened: false,
    };
  },
  computed: {
    musicList: () => musicList,
    musicIndex() {
      return this.$store.state.musicPlayer.musicIndex;
    },
    paused() {
      return this.$store.state.musicPlayer.paused;
    },
  },
  methods: {
    // 屏幕滚动修改导航栏颜色与透明度
    headerScroll() {
      this.backgroundAlpha = Math.min(window.scrollY / this.$rs(500), 1);
    },
  },
  watch: {
    "$route.query.settings"(newValue) {
      if (newValue === undefined) return;
      if (this.$refs.settings.barList[newValue]) {
        this.menuChecked = true;
        this.settingsOpened = true;
        this.$refs.settings.barIndex = Number(newValue);
      } else {
        this.menuChecked = false;
        this.settingsOpened = false;
      }
      this.$root.removeQuery("settings");
    },
  },
  created() {
    window.addEventListener("scroll", this.headerScroll);
    this.headerScroll();
  },
};
</script>

<style lang="scss" scoped>
#header {
  width: 100%;
  position: fixed;
  z-index: 100;
  top: 0;
  @media (min-width: 768px) {
    height: 100px;
  }
  @media (max-width: 767px) {
    height: 15vw;
  }
  .container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    .header-logo {
      height: 100%;
      a.logo {
        height: 100%;
        display: flex;
        align-items: center;
        color: rgba(var(--color0), 1);
        @media (min-width: 768px) {
          padding: 0 8px;
          font-size: 48px;
          line-height: 100px;
        }
        @media (max-width: 767px) {
          padding: 0 1.2vw;
          font-size: 7.2vw;
          line-height: 15vw;
        }
      }
    }
    .header-nav {
      height: 100%;
      @media (min-width: 768px) {
        display: flex;
      }
      @media (max-width: 767px) {
        display: none;
      }
      .nav-item {
        height: 100%;
        padding: 0 30px;
        border-bottom: 0 solid #000;
        font-size: 18px;
        text-align: center;
        line-height: 100px;
        color: #ccc;
        display: block;
        &.active {
          color: rgba(var(--color0), 1);
          background-color: rgba(var(--color1), 0.2);
          border-bottom: 2px solid rgba(var(--color0), 1);
          cursor: default;
        }
        &:not(.active):hover {
          background-color: rgba(var(--color2), 0.2);
        }
      }
    }
    .header-portrait {
      width: 60px;
      height: 60px;
      margin: 20px 0;
      @media (min-width: 992px) {
        position: relative;
        @media (hover: hover) {
          &:hover {
            .popup {
              width: 240px;
            }
            .settings {
              opacity: 1;
              .svg-settings {
                transform: rotate(0);
              }
            }
          }
        }
        @media (hover: none) {
          margin-left: 240px;
        }
      }
      @media (min-width: 768px) and (max-width: 991px) {
        position: absolute;
        right: 0;
      }
      img {
        width: 60px;
        height: 60px;
        @media (max-width: 991px) {
          display: none;
        }
        &:not(.settings-opened) {
          cursor: pointer;
        }
      }
      .popup {
        height: 64px;
        background-color: rgba(var(--color-bg-dark3), 0.6);
        border: 2px solid rgba(var(--color2-dark3), 0.8);
        position: absolute;
        z-index: 99;
        overflow: hidden;
        @media (min-width: 992px) {
          border-radius: 20px 0 0 20px;
          top: -2px;
          right: 60px;
          @media (hover: hover) {
            width: 0;
          }
          @media (hover: none) {
            width: 240px;
          }
        }
        @media (min-width: 768px) and (max-width: 991px) {
          width: 320px;
          border-radius: 0 0 20px 20px;
          right: 0;
          top: 80px;
        }
        @media (max-width: 767px) {
          display: none;
        }
        &.hidden {
          width: 0;
          border-width: 0;
        }
        .bgm-box {
          width: 240px;
          height: 60px;
          display: flex;
          align-items: center;
          .bgm-btn {
            width: 50px;
            height: 50px;
            margin: 5px;
            font-size: 30px;
            color: rgba(var(--color2-light1), 1);
            cursor: pointer;
          }
          .bgm-info {
            flex: 1;
            padding-right: 10px;
            color: #ccc;
            overflow: hidden;
            .name {
              font-size: 18px;
            }
            .artist {
              font-size: 12px;
            }
            .name,
            .artist {
              white-space: nowrap;
              overflow: hidden;
            }
          }
        }
      }
      .settings {
        width: 100%;
        height: 100%;
        min-height: 60px;
        position: absolute;
        right: 0;
        z-index: 999;
        overflow: hidden;
        @media (min-width: 768px) {
          transform: translate3d(0, -50%, 0);
          @media (min-width: 992px) {
            top: 50%;
            @media (hover: hover) {
              opacity: 0;
            }
          }
          @media (max-width: 991px) {
            top: 112px;
            &:hover {
              .settings-header {
                background-color: #0009;
              }
            }
          }
        }
        @media (max-width: 767px) {
          width: 0;
          height: 0;
        }
        &.opened {
          height: calc(100vh - 240px);
          min-height: 640px;
          background-color: rgba(var(--color-bg-dark2), 0.8);
          border-radius: 12px;
          @media (min-width: 768px) {
            top: calc(50vh - 20px);
            @media (min-width: 1200px) {
              width: 1170px;
            }
            @media (min-width: 992px) and (max-width: 1199px) {
              width: 970px;
            }
            @media (max-width: 991px) {
              width: 750px;
            }
          }
          @media (hover: hover) {
            opacity: 1;
          }
          .settings-header {
            height: 40px;
            @media (min-width: 768px) and (max-width: 991px) {
              background-color: #0009;
            }
          }
          .settings-body {
            @media (min-width: 768px) {
              height: calc(100% - 40px);
            }
          }
          .svg-settings {
            font-size: 24px;
            color: #fff;
            position: absolute;
            top: 8px;
            left: 8px;
            opacity: 0.8;
            @media (hover: hover) {
              transform: rotate(0);
            }
          }
        }
        &:not(.opened) {
          cursor: pointer;
          @media (min-width: 992px) and (hover: hover) {
            pointer-events: none;
          }
        }
        .settings-header {
          height: 60px;
          position: relative;
          @media (min-width: 992px) {
            background-color: #0009;
            @media (min-width: 1200px) {
              width: 1170px;
            }
            @media (max-width: 1199px) {
              width: 970px;
            }
          }
          @media (min-width: 768px) and (max-width: 991px) {
            width: 750px;
            background-color: #0000;
          }
          @media (max-width: 767px) {
            display: none;
          }
          .close-btn {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 0;
            cursor: pointer;
          }
          .svg-close {
            font-size: 24px;
            color: #ccc;
            opacity: 0.8;
          }
        }
        .settings-body {
          height: 0;
          background-color: rgba(var(--color-bg-dark2), 0.8);
          @media (max-width: 767px) {
            width: 0;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 999;
            overflow: hidden;
            &.show {
              width: 100vw;
              height: 100vh;
            }
            .settings-panel {
              width: 100vw;
              height: 100vh;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
        }
        .svg-settings {
          font-size: 48px;
          color: #fff;
          position: absolute;
          top: 6px;
          left: 6px;
          opacity: 0.8;
          @media (min-width: 992px) {
            @media (hover: hover) {
              transform: rotate(-90deg);
            }
          }
          @media (min-width: 768px) and (max-width: 991px) {
            transform: rotate(0);
          }
          @media (max-width: 767px) {
            display: none;
          }
        }
      }
    }
    .header-menu {
      width: 15vw;
      height: 15vw;
      @media (min-width: 768px) {
        display: none;
      }
      .menu-btn {
        width: 100%;
        height: 100%;
        font-size: 9vw;
        color: #ccc;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 999;
        cursor: pointer;
        &.hide {
          transform: translate3d(100vw, 0, 0);
        }
      }
      .mask {
        width: 0;
        height: 100vh;
        background: #0000;
        position: fixed;
        top: 0;
        right: 0;
        z-index: 998;
        &.show {
          width: 100vw;
          background: #000c;
        }
      }
      .menu-list {
        position: fixed;
        top: 15vw;
        right: -100vw;
        z-index: 999;
        &.show {
          transform: translate3d(-100vw, 0, 0);
        }
        .menu-item {
          height: 15vw;
          padding: 0 5vw;
          border-bottom: 1px solid #666;
          font-size: 4.8vw;
          text-align: right;
          line-height: 15vw;
          color: #ccc;
          display: block;
          box-sizing: content-box;
          &:nth-of-type(1) {
            border-top: 1px solid #666;
          }
          &.active {
            background-image: linear-gradient(
              to left,
              rgba(var(--color1-dark2), 0.5),
              rgba(var(--color1-dark2), 0)
            );
            color: rgba(var(--color0), 1);
          }
          &:hover {
            color: rgba(var(--color0), 1);
          }
        }
      }
      .settings-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        z-index: 999;
        cursor: pointer;
        @media (hover: hover) {
          &:hover {
            .svg-settings {
              opacity: 0.4;
            }
          }
        }
        &.open {
          width: 12vw;
          height: 12vw;
          left: 100vw;
          background-color: #0009;
          transform: translate3d(-100vw, 0, 0);
          .svg-settings {
            font-size: 7.2vw;
            opacity: 0.8;
            transform: rotate3d(0, 0, 1, 90deg);
          }
        }
        &:not(.open) {
          width: 15vw;
          height: 15vw;
          left: 101.25vw;
          &.show {
            transform: translate3d(-100vw, 0, 0);
          }
        }
        .svg-settings {
          font-size: 9vw;
          color: #fff;
          opacity: 0.8;
        }
      }
    }
    .bgm-icon {
      width: 15vw;
      height: 15vw;
      margin-right: 1.25vw;
      border-radius: 50%;
      font-size: 9vw;
      color: #ccc;
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      top: 15vw;
      right: 0;
      z-index: 99;
      animation: bgm-icon-round 7.5s linear infinite;
      @keyframes bgm-icon-round {
        0% {
          transform: rotate(0);
        }
        100% {
          transform: rotate(360deg);
        }
      }
      @media (min-width: 768px) {
        display: none;
      }
      @media (hover: hover) {
        cursor: pointer;
      }
    }
  }
}
</style>