import {
  createStore
} from "vuex";

import functionKey from "./functionKey";
import musicPlayer from "./musicPlayer";
import spectrumEffect from "./spectrumEffect";
import bgEffect from "./bgEffect";

export default createStore({
  state: {
    theme: "default",
    themeList: ["default", "flame"],
  },
  mutations: {
    init(state) {
      // 获取本地设置
      let theme = localStorage.getItem("theme");
      if (state.themeList.indexOf(theme) > -1) {
        state.theme = theme;
        document.body.setAttribute("data-theme", theme);
      }
    },
    theme(state, value) {
      if (state.themeList.indexOf(value) > -1) {
        state.theme = value;
        document.body.setAttribute("data-theme", value);
        localStorage.setItem("theme", value);
      }
    },
  },
  actions: {
    create(context) {
      context.commit("init");
      document.body.setAttribute("data-theme", context.state.theme);
      context.dispatch("functionKey/create");
      context.dispatch("musicPlayer/create");
      context.dispatch("spectrumEffect/create");
      context.dispatch("bgEffect/create");
    },
    changeTheme(context, value) {
      context.commit("theme", value);
    },
  },
  modules: {
    functionKey,
    musicPlayer,
    spectrumEffect,
    bgEffect,
  },
});