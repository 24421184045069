<template>
  <div class="settings-panel">
    <div class="bar">
      <div
        v-for="(item, index) in barList"
        :key="item.tab"
        class="menu"
        :class="{ active: index === barIndex }"
        @click="barIndex = index"
      >
        {{ item.tab }}
      </div>
    </div>
    <div class="content">
      <div
        v-for="(item, index) in barList"
        :key="item.component"
        class="content-box"
        :class="{ front: barIndex > index, back: barIndex < index }"
      >
        <component :is="item.component" />
      </div>
    </div>
  </div>
</template>

<script>
// import MusicPlayer from "./settings-panel/MusicPlayer";
// import SpectrumEffect from "./settings-panel/SpectrumEffect";
import BgEffect from "./settings-panel/BgEffect";
export default {
  components: {
    // MusicPlayer,
    // SpectrumEffect,
    BgEffect,
  },
  data() {
    return {
      barList: [
        // { tab: "背景音乐", component: "MusicPlayer" },
        // { tab: "频谱动效", component: "SpectrumEffect" },
        { tab: "背景特效", component: "BgEffect" },
      ],
      barIndex: 0,
    };
  },
  computed: {
    paused() {
      return this.$store.state.musicPlayer.paused;
    },
  },
  methods: {
    // 播放/暂停
    pausedClick() {
      if (this.paused) {
        this.$store.dispatch("musicPlayer/play");
      } else {
        this.$store.dispatch("musicPlayer/pause");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.settings-panel {
  width: 100%;
  height: 100%;
  display: flex;
  @media (max-width: 767px) {
    flex-direction: column;
  }
  .bar {
    background-color: #0003;
    @media (min-width: 768px) {
      width: 120px;
      height: 100%;
    }
    @media (max-width: 767px) {
      height: 12vw;
      margin-left: 12vw;
      display: flex;
      overflow: auto hidden;
    }
    .menu {
      text-align: center;
      color: #ccc;
      @media (min-width: 768px) {
        width: 100%;
        height: 60px;
        font-size: 16px;
        line-height: 60px;
      }
      @media (max-width: 767px) {
        flex: 1;
        min-width: 20vw;
        height: 100%;
        line-height: 12vw;
      }
      &.active {
        color: rgba(var(--color0), 1);
        @media (min-width: 768px) {
          background-image: linear-gradient(
            to right,
            rgba(var(--color1), 0.2),
            rgba(var(--color1), 0)
          );
        }
        @media (max-width: 767px) {
          background-image: linear-gradient(
            to right,
            rgba(var(--color1), 0.2),
            rgba(var(--color1), 0.2)
          );
        }
      }
      &:not(.active) {
        cursor: pointer;
        &:hover {
          color: rgba(var(--color0), 1);
        }
      }
    }
  }
  .content {
    flex: 1;
    position: relative;
    overflow: hidden;
    @media (min-width: 768px) {
      height: 100%;
    }
    @media (max-width: 767px) {
      width: 100%;
    }
    .content-box {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow-y: auto;
      &.front {
        @media (min-width: 768px) {
          transform: translate3d(0, -100%, 0);
        }
        @media (max-width: 767px) {
          transform: translate3d(-100%, 0, 0);
        }
      }
      &.back {
        @media (min-width: 768px) {
          transform: translate3d(0, 100%, 0);
        }
        @media (max-width: 767px) {
          transform: translate3d(100%, 0, 0);
        }
      }
    }
  }
}
</style>

<style lang="scss">
.settings-panel {
  .content {
    .content-box {
      & > div {
        height: 100%;
        overflow: hidden auto;
        overscroll-behavior: contain;
        @media (min-width: 768px) {
          padding: 20px;
        }
        @media (max-width: 767px) {
          padding: 4vw;
        }
        .settings-row {
          width: 100%;
          display: inline-flex;
          @media (max-width: 767px) {
            flex-wrap: wrap;
          }
          & + .settings-row {
            @media (min-width: 768px) {
              margin-top: 20px;
            }
            @media (max-width: 767px) {
              margin-top: 4vw;
            }
          }
          &.settings-row-half {
            @media (min-width: 768px) {
              width: 50%;
            }
          }
          &.invalid {
            filter: grayscale(1);
          }
          &.disabled {
            filter: grayscale(1);
            cursor: not-allowed;
            .settings-label,
            .settings-value {
              pointer-events: none;
            }
          }
          .settings-label {
            text-align: right;
            @media (min-width: 768px) {
              width: 100px;
              min-width: 100px;
              margin-right: 8px;
              font-size: 16px;
              line-height: 40px;
            }
            @media (max-width: 767px) {
              width: 20vw;
              min-width: 20vw;
              margin-right: 1.6vw;
              font-size: 3.2vw;
              line-height: 8vw;
            }
          }
          .settings-value {
            display: flex;
            align-items: center;
            @media (min-width: 768px) {
              flex: 1;
            }
            @media (max-width: 767px) {
              width: 100%;
              min-height: 8vw;
            }
            .pui-slider {
              .pui-slider-value {
                @media (min-width: 768px) {
                  width: 50px;
                }
                @media (max-width: 767px) {
                  width: 10vw;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>