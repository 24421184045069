export const maskElement = document.createElement("div");
Object.assign(maskElement.style, {
  width: "100%",
  height: "100%",
  display: "none",
  position: "fixed",
  top: "0",
  right: "0",
  bottom: "0",
  left: "0",
  zIndex: "99999",
});
document.documentElement.append(maskElement);