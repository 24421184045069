<template>
  <div class="pui-radio" :class="{ 'pui-radio-active': value }">
    <div class="pui-radio-circle"></div>
    <div v-if="$slots.default" class="pui-radio-label">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.pui-radio {
  color: rgba(var(--color0-light4), 1);
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    .pui-radio-circle {
      opacity: 0.8;
    }
  }
  & + .pui-radio {
    @media (min-width: 768px) {
      margin-left: 8px;
    }
    @media (max-width: 767px) {
      margin-left: 1.6vw;
    }
  }
  &.pui-radio-active {
    .pui-radio-circle {
      border-color: rgba(var(--color0), 0.8);
      background-color: rgba(var(--color2-dark4), 0.5);
      color: rgba(var(--color0-light2), 1);
      @media (min-width: 768px) {
        box-shadow: 0 0 5px rgba(var(--color0), 0.8);
      }
      @media (max-width: 767px) {
        box-shadow: 0 0 1vw rgba(var(--color0), 0.8);
      }
      &::after {
        background-color: rgba(var(--color0), 1);
      }
    }
  }
  .pui-radio-circle {
    border-color: rgba(var(--color0-dark2), 0.8);
    border-style: solid;
    border-radius: 50%;
    @media (min-width: 768px) {
      width: 16px;
      height: 16px;
      padding: 2px;
      border-width: 2px;
      text-shadow: 0 0 10px #000;
    }
    @media (max-width: 767px) {
      width: 3.2vw;
      height: 3.2vw;
      padding: 0.4vw;
      border-width: 0.4vw;
      text-shadow: 0 0 2vw #000;
    }
    &::after {
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 50%;
      display: block;
    }
  }
  .pui-radio-label {
    @media (min-width: 768px) {
      margin-left: 4px;
    }
    @media (max-width: 767px) {
      margin-left: 0.8vw;
    }
  }
}
</style>