let windowResizeTimer;
let windowResizeFuncList = [];
let windowWidth = window.innerWidth;
let sizeScale = 0.2;
let sizeBase = windowWidth >= 768 ? 1 : windowWidth * 0.01 * sizeScale;

export const responseSize = (size) => size * sizeBase;
export const toVw = (size) => size * sizeScale;
export const fromVw = (size) => size / sizeScale;
export const windowResizeFuncAdd = (func, repeatAllowed) => {
  let index = windowResizeFuncList.indexOf(func);
  if (repeatAllowed || index < 0) {
    return windowResizeFuncList.push(func);
  }
  return index;
};
export const windowResizeFuncRemove = (func) => {
  let index = windowResizeFuncList.indexOf(func);
  if (index > -1) {
    windowResizeFuncList.splice(index, 1);
    return index;
  }
  return false;
};

const reset = () => {
  clearTimeout(windowResizeTimer);
  windowResizeTimer = setTimeout(() => {
    clearTimeout(windowResizeTimer);
    sizeBase = windowWidth >= 768 ? 1 : windowWidth * 0.01 * sizeScale;
    windowResizeFuncList.forEach(item => item());
  }, 300);
}

window.addEventListener("resize", reset);