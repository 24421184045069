<template>
  <div
    class="pui-slider"
    :class="{
      'pui-slider-horizontal': !vertical,
      'pui-slider-vertical': vertical,
      'pui-slider-invalid': invalid,
      'pui-slider-disabled': disabled,
    }"
  >
    <div
      ref="slider"
      class="pui-slider-warpper"
      :class="{ 'pui-slider-warpper-holding': holding !== false }"
      @mousedown.prevent="mousedown"
      @touchstart.prevent="touchstart"
    >
      <div
        v-if="range"
        class="pui-slider-block"
        :class="{ 'pui-slider-block-holding': holding !== false }"
        :style="{
          left: vertical ? '50%' : `${progress[0] * 100}%`,
          bottom: vertical ? `${progress[0] * 100}%` : '50%',
        }"
        @mousedown="holdingBlock(0)"
        @touchstart="holdingBlock(0)"
      ></div>
      <div
        class="pui-slider-track"
        :class="{
          'pui-slider-track-horizontal': !vertical,
          'pui-slider-track-vertical': vertical,
        }"
      >
        <div
          class="pui-slider-progress"
          :style="{
            width: vertical ? '100%' : `${(progress[1] - progress[0]) * 100}%`,
            height: vertical ? `${(progress[1] - progress[0]) * 100}%` : '100%',
            marginLeft: vertical ? '0' : `${progress[0] * 100}%`,
            marginBottom: vertical ? `${progress[0] * 100}%` : '0',
          }"
        ></div>
        <template v-if="step > 0 && showStepDot">
          <div
            v-for="item in step - 1"
            :key="item"
            class="pui-slider-step-dot"
            :style="{
              left: vertical ? '50%' : `${stepScale * item * 100}%`,
              bottom: vertical ? `${stepScale * item * 100}%` : '50%',
            }"
          ></div
        ></template>
      </div>
      <div
        class="pui-slider-block"
        :class="{ 'pui-slider-block-holding': holding !== false }"
        :style="{
          left: vertical ? '50%' : `${progress[1] * 100}%`,
          bottom: vertical ? `${progress[1] * 100}%` : '50%',
        }"
        @mousedown="holdingBlock(1)"
        @touchstart="holdingBlock(1)"
      ></div>
    </div>
    <template v-if="showValue">
      <template v-if="responseSize > -1">
        <div class="pui-slider-text _pc">{{ responseText_pc }}</div>
        <div class="pui-slider-text _mobile">{{ responseText_mobile }}</div>
      </template>
      <div v-else class="pui-slider-text">{{ valueText }}</div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    value: [Number, Array],
    vertical: Boolean,
    step: Number,
    min: { type: Number, default: 0 },
    max: { type: Number, default: 1 },
    range: Boolean,
    showStepDot: Boolean,
    showValue: [Boolean, Function],
    responseSize: Number,
    invalid: Boolean,
    disabled: Boolean,
  },
  data() {
    return {
      cValue: [0, 0],
      holding: false,
      holdDebounce: false,
      touchId: null,
      holdingOffset: [0, 0],
    };
  },
  computed: {
    progress() {
      return this.cValue.map(
        (item) => (item - this.min) / (this.max - this.min)
      );
    },
    stepScale() {
      return 1 / (this.step || 1);
    },
    valueText() {
      let text = this.range ? this.cValue : this.cValue[1];
      return typeof this.showValue === "function"
        ? this.showValue(text)
        : this.range
        ? `${text[0]} - ${text[1]}`
        : text;
    },
    responseText_pc() {
      let text = this.range
        ? this.cValue[0].toFixed(this.responseSize) + " - "
        : "";
      text += this.cValue[1].toFixed(this.responseSize) + "px";
      return typeof this.showValue === "function" ? this.showValue(text) : text;
    },
    responseText_mobile() {
      let text = this.range
        ? this.$tv(this.cValue[0]).toFixed(this.responseSize) + " - "
        : "";
      text += this.$tv(this.cValue[1]).toFixed(this.responseSize) + "vw";
      return typeof this.showValue === "function" ? this.showValue(text) : text;
    },
  },
  methods: {
    // 鼠标点击进度条
    mousedown(event) {
      this.holding = true;
      if (this.range) this.setHoldingOffset(event.clientX, event.clientY);
      this.holdingMove(event.clientX, event.clientY);
    },
    // 手指按下进度条
    touchstart(event) {
      this.holding = true;
      let touch = event.changedTouches[0];
      this.touchId = touch.identifier;
      if (this.range) this.setHoldingOffset(touch.clientX, touch.clientY);
      this.holdingMove(touch.clientX, touch.clientY);
    },
    // 点击滑块
    holdingBlock(index) {
      setTimeout(() => {
        this.holding = index;
      }, 0);
    },
    // 设置偏移
    setHoldingOffset(x, y) {
      let slider = this.$refs.slider.getBoundingClientRect();
      let progress = this.vertical
        ? (slider.bottom - y) / slider.height
        : (x - slider.left) / slider.width;
      this.holdingOffset = this.cValue.map(
        (item) => (item - this.min) / (this.max - this.min) - progress
      );
    },
    // 鼠标移动
    mousemove(event) {
      if (this.holding !== false)
        this.holdingMove(event.clientX, event.clientY);
    },
    // 触摸屏手指移动
    touchmove(event) {
      if (this.holding !== false) {
        for (let item of event.touches) {
          if (item.identifier === this.touchId) {
            this.holdingMove(item.clientX, item.clientY);
            break;
          }
        }
      }
    },
    // 进度条滑块移动
    holdingMove(x, y) {
      if (this.holdDebounce) return;
      this.holdDebounce = true;
      this.$nextFrame(() => (this.holdDebounce = false));
      if (this.holding !== false) {
        let slider = this.$refs.slider.getBoundingClientRect();
        let progress = this.vertical
          ? (slider.bottom - y) / slider.height
          : (x - slider.left) / slider.width;
        if (this.range && this.holding === true) {
          this.cValue = this.holdingOffset.map((item, index) => {
            let offset = this.cValue[1] - this.cValue[0];
            let value = progress + item;
            if (this.step > 0)
              value = Math.round(value / this.stepScale) * this.stepScale;
            value = this.min + value * (this.max - this.min);
            value = index
              ? Math.min(Math.max(value, this.min + offset), this.max)
              : Math.min(Math.max(value, this.min), this.max - offset);
            return value;
          });
          this.$emit("update:value", this.cValue);
          this.$emit("change", this.cValue);
        } else {
          progress = Math.min(Math.max(progress, 0), 1);
          if (this.step > 0)
            progress = Math.round(progress / this.stepScale) * this.stepScale;
          if (this.range) {
            this.cValue[this.holding] =
              this.min + progress * (this.max - this.min);
            if (this.cValue[0] > this.cValue[1]) {
              let min = this.cValue[1];
              this.cValue[1] = this.cValue[0];
              this.cValue[0] = min;
              this.holding = Number(!this.holding);
            }
            this.$emit("update:value", this.cValue);
            this.$emit("change", this.cValue);
          } else {
            this.cValue[1] = this.min + progress * (this.max - this.min);
            this.$emit("update:value", this.cValue[1]);
            this.$emit("change", this.cValue[1]);
          }
        }
      }
    },
    // 触摸屏手指抬起
    touchend(event) {
      if (event.changedTouches[0].identifier === this.touchId) {
        this.touchId = null;
        this.holdingEnd();
      }
    },
    // 进度条滑块移动结束
    holdingEnd() {
      this.holding = false;
      this.$emit("changeEnd", this.range ? this.cValue : this.cValue[1]);
    },
  },
  watch: {
    value: {
      handler(newValue) {
        if (this.holding !== false) return;
        if (newValue.map)
          this.cValue = newValue.map((item) =>
            Math.min(Math.max(item, this.min), this.max)
          );
        else {
          this.cValue[0] = this.min;
          this.cValue[1] = Math.min(Math.max(newValue, this.min), this.max);
        }
      },
      immediate: true,
    },
    holding(newValue) {
      if (newValue !== false) {
        Object.assign(this.$mask.style, {
          display: "block",
          cursor: "grabbing",
        });
        window.addEventListener("mousemove", this.mousemove);
        window.addEventListener("touchmove", this.touchmove);
        window.addEventListener("mouseup", this.holdingEnd);
        window.addEventListener("mouseleave", this.holdingEnd);
        window.addEventListener("touchend", this.touchend);
      } else {
        this.$mask.style.display = "none";
        window.removeEventListener("mousemove", this.mousemove);
        window.removeEventListener("touchmove", this.touchmove);
        window.removeEventListener("mouseup", this.holdingEnd);
        window.removeEventListener("mouseleave", this.holdingEnd);
        window.removeEventListener("touchend", this.touchend);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pui-slider {
  flex: 1;
  display: flex;
  align-items: center;
  @media (min-width: 768px) {
    height: 16px;
    padding: 8px;
  }
  @media (max-width: 767px) {
    height: 4vw;
    padding: 2vw;
  }
  &.pui-slider-horizontal {
    width: 100%;
    .pui-slider-warpper {
      height: 0;
    }
    .pui-slider-text {
      @media (min-width: 768px) {
        margin-left: 16px;
      }
      @media (max-width: 767px) {
        margin-left: 3.2vw;
      }
    }
  }
  &.pui-slider-vertical {
    height: 100%;
    flex-direction: column-reverse;
    .pui-slider-warpper {
      width: 0;
    }
    .pui-slider-text {
      @media (min-width: 768px) {
        margin-bottom: 16px;
      }
      @media (max-width: 767px) {
        margin-bottom: 3.2vw;
      }
    }
  }
  &.pui-slider-invalid,
  &.pui-slider-disabled {
    filter: grayscale(1);
  }
  &.pui-slider-disabled {
    cursor: not-allowed;
    & > * {
      pointer-events: none;
    }
  }
  .pui-slider-warpper {
    flex: 1;
    position: relative;
    cursor: pointer;
    .pui-slider-track {
      background-color: #9999;
      display: flex;
      align-items: flex-end;
      overflow: hidden;
      @media (min-width: 768px) {
        margin: -2px;
        border-radius: 2px;
      }
      @media (max-width: 767px) {
        margin: -0.5vw;
        border-radius: 0.5vw;
      }
      &.pui-slider-track-horizontal {
        width: 100%;
        @media (min-width: 768px) {
          height: 4px;
        }
        @media (max-width: 767px) {
          height: 1vw;
        }
      }
      &.pui-slider-track-vertical {
        height: 100%;
        @media (min-width: 768px) {
          width: 4px;
        }
        @media (max-width: 767px) {
          width: 1vw;
        }
      }
      .pui-slider-progress {
        background-color: rgba(var(--color0), 0.5);
        transition-property: none;
      }
      .pui-slider-step-dot {
        background-color: #fff3;
        border-radius: 50%;
        position: absolute;
        transform: translate3d(-50%, 50%, 0);
        transition-property: none;
        @media (min-width: 768px) {
          width: 4px;
          height: 4px;
        }
        @media (max-width: 767px) {
          width: 1vw;
          height: 1vw;
        }
      }
    }
    .pui-slider-block {
      background-color: rgba(var(--color0), 1);
      border-radius: 50%;
      position: absolute;
      transform: translate3d(-50%, 50%, 0);
      transition-property: box-shadow;
      cursor: grab;
      @media (min-width: 768px) {
        width: 16px;
        height: 16px;
        box-shadow: 0 0 8px #0009;
      }
      @media (max-width: 767px) {
        width: 4vw;
        height: 4vw;
        box-shadow: 0 0 2vw #0009;
      }
      &.pui-slider-block-holding {
        @media (min-width: 768px) {
          box-shadow: 0 0 8px rgba(var(--color0), 1);
        }
        @media (max-width: 767px) {
          box-shadow: 0 0 2vw rgba(var(--color0), 1);
        }
      }
    }
  }
  .pui-slider-text {
    @media (min-width: 768px) {
      font-size: 14px;
    }
    @media (max-width: 767px) {
      font-size: 2.8vw;
    }
  }
}
</style>