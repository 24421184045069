<template>
  <div id="app">
    <TheHeader />
    <div ref="main" id="main">
      <router-view />
    </div>
    <TheFooter />
    <TheBackground />
    <TheCover />
    <!-- <TheLamplight /> -->
    <!-- <TheOpening /> -->
  </div>
</template>

<script>
import TheHeader from "@/components/TheHeader";
import TheFooter from "@/components/TheFooter";
import TheBackground from "@/components/TheBackground";
import TheCover from "@/components/TheCover";
// import TheLamplight from "@/components/TheLamplight";
// import TheOpening from "@/components/TheOpening";

export default {
  name: "app",
  components: {
    TheHeader,
    TheFooter,
    TheBackground,
    TheCover,
    // TheLamplight,
    // TheOpening,
  },
  data() {
    return {};
  },
  computed: {
    theme() {
      return this.$store.state.theme;
    },
  },
  methods: {
    // 设置路由参数
    setQuery(name, value) {
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query[name] = String(value);
      this.$router.replace({ query });
    },
    // 移除路由参数
    removeQuery(name) {
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query[name] = undefined;
      this.$router.replace({ query });
    },
  },
  created() {
    this.$store.dispatch("create");
  },
};
</script>

<style lang="scss" scoped>
#app {
  min-height: 100vh;
  overflow: hidden;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  #main {
    min-height: calc(100vh - 100px);
    padding-top: 100px;
    @media (max-width: 767px) {
      min-height: calc(100vh - 15.15vw);
      padding-top: 15vw;
    }
  }
}
</style>

<style lang="scss">
// 主题颜色
@import "@/assets/css/_themes.scss";
@mixin map-themes {
  $scale-list: (
    1: 0.8,
    2: 0.6,
    3: 0.4,
    4: 0.2,
    5: 0.1,
  );
  @each $theme-name, $color-list in $themes {
    body[data-theme="#{$theme-name}"] {
      @each $name, $value in $color-list {
        $new-value: "";
        @each $color in $value {
          @if $new-value == "" {
            $new-value: #{$color + "%"};
          } @else {
            $new-value: #{$new-value}, #{$color + "%"};
          }
        }
        --#{$name}: #{$new-value};
        @each $index, $scale in $scale-list {
          $dark-color: "";
          $light-color: "";
          @each $color in $value {
            @if $dark-color == "" {
              $dark-color: #{$color * $scale + "%"};
            } @else {
              $dark-color: #{$dark-color}, #{$color * $scale + "%"};
            }
            @if $light-color == "" {
              $light-color: #{(100 - (100 - $color) * $scale) + "%"};
            } @else {
              $light-color: #{$light-color},
                #{(100 - (100 - $color) * $scale) + "%"};
            }
          }
          --#{$name}-dark#{$index}: #{$dark-color};
          --#{$name}-light#{$index}: #{$light-color};
        }
      }
    }
  }
}
@include map-themes;
* {
  // 内减盒子模式
  box-sizing: border-box;
  &:not(.no-animate) {
    // ⽤户未开启减弱动画设置
    @media (prefers-reduced-motion: no-preference) {
      &,
      &::before,
      &::after {
        // 全局动画效果
        transition-property:
          // CSS属性
          width, min-width, max-width, height, min-height, max-height, flex,
          margin, padding, background, background-image, border, border-radius,
          box-shadow, top, right, bottom, left, opacity, transform, filter,
          clip-path,
          // SVG属性
          d,
          cx, cy, rx, ry, r, fill, stroke, stroke-width;
        transition-duration: 0.3s;
        transition-timing-function: ease-in-out;
      }
    }
  }
}
@media (min-width: 768px) {
  ::-webkit-scrollbar {
    // 滚动条整体部分
    width: 12px;
    height: 12px;
    background-color: rgba(var(--color-bg-dark2), 1);
  }
  ::-webkit-scrollbar-thumb {
    // 滚动条里面的小方块，能向上向下移动（或往左往右移动，取决于是垂直滚动条还是水平滚动条）
    background-color: #ccc3;
    border: 2px solid rgba(var(--color-bg-dark2), 1);
    border-radius: 6px;
  }
  ::-webkit-scrollbar-track {
    // 滚动条的轨道（里面装有Thumb）
    border-radius: 6px;
  }
  ::-webkit-scrollbar-button {
    // 滚动条的轨道的两端按钮，允许通过点击微调小方块的位置。
    width: 2px;
    height: 2px;
  }
  ::-webkit-scrollbar-track-piece {
    // 内层轨道，滚动条中间部分（除去）
  }
  ::-webkit-scrollbar-corner {
    // 边角，即两个滚动条的交汇处
    background-color: rgba(var(--color-bg-dark3), 1);
    border-color: rgba(var(--color-bg-dark2), 1) rgba(var(--color-bg-dark4), 1)
      rgba(var(--color-bg-dark4), 1) rgba(var(--color-bg-dark2), 1);
    border-style: solid;
    border-width: 3px;
  }
  ::-webkit-resizer {
    // 两个滚动条的交汇处上用于通过拖动调整元素大小的小控件
    background-color: rgba(var(--color-bg-dark3), 1);
    border-color: rgba(var(--color-bg-dark2), 1) rgba(var(--color-bg-dark4), 1)
      rgba(var(--color-bg-dark4), 1) rgba(var(--color-bg-dark2), 1);
    border-style: solid;
    border-width: 3px;
  }
}
html {
  scroll-behavior: smooth;
}
body {
  height: 100vh;
  background: rgba(var(--color-bg-dark5), 1);
  line-height: 1.5;
  color: rgba(var(--color0-light5), 1);
  @media (min-width: 768px) {
    font-size: 14px;
  }
  @media (max-width: 767px) {
    font-size: 2.8vw;
  }
}
#app {
  .container {
    margin: 0 auto;
    @media (min-width: 1200px) {
      width: 1170px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      width: 970px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      width: 750px;
    }
    @media (max-width: 767px) {
      width: 97.5vw;
    }
  }
  a {
    text-decoration: none;
    color: inherit;
    /* 取消a链接背景高亮 */
    -webkit-tap-highlight-color: transparent;
  }
  input {
    border: 0;
    outline-style: none;
  }
  ._fcc {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ._frcc {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
  }
  ._fccc {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  ._fcrcc {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
  ._pc {
    @media (max-width: 767px) {
      display: none;
    }
  }
  ._mobile {
    @media (min-width: 768px) {
      display: none;
    }
  }
}
</style>
