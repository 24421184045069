import {
  spectrumColorOptions
} from "@/assets/js/spectrumColorOptions.js";

export default {
  namespaced: true,
  state: {
    spectrumColorIndex: 0,
    spectrumColorData: [{
        id: 0,
        position: 0,
        color: [51, 204, 255, 0.2],
      },
      {
        id: 1,
        position: 0.4,
        color: [51, 51, 255, 0.6],
      },
      {
        id: 2,
        position: 0.8,
        color: [204, 51, 255, 1],
      },
      {
        id: 3,
        position: 1,
        color: [255, 51, 204, 1],
      },
    ],
    spectrumLineLength: 0.2,
    spectrumLineNum: Math.round(window.innerWidth / 40),
    spectrumNumAccording: false,
    spectrumLineWidth: 10,
    spectrumLineGap: 10,
  },
  getters: {},
  mutations: {
    // 初始化
    init(state) {
      // 获取本地设置
      let index = Math.round(localStorage.getItem("spectrumColorIndex"));
      if (spectrumColorOptions[index]) {
        state.spectrumColorIndex = index;
      } else {
        state.spectrumColorIndex = 0;
      }
      let colorData = JSON.parse(localStorage.getItem("spectrumColorData"));
      if (colorData && colorData.map) {
        state.spectrumColorData = colorData.map((item, index) => ({
          id: index,
          position: Math.min(Math.max(Number(item.position), 0), 1) || 0,
          color: item.color.map((color, index) => {
            if (index > 2) return Math.min(Math.max(color, 0), 1) || 0;
            return Math.min(Math.max(Math.round(color), 0), 255) || 0;
          }),
        }));
      }
      state.spectrumLineLength = Math.min(Math.max(Number(localStorage.getItem("spectrumLineLength") || 0.2) || 0.2, 0), 1);
      let lineNumDefault = window.innerWidth >= 768 ? Math.round(window.innerWidth / 40) : 10;
      state.spectrumLineNum = Math.min(Math.max(Number(localStorage.getItem("spectrumLineNum") || lineNumDefault) || lineNumDefault, 0), window.innerWidth >= 768 ? Math.round(window.innerWidth / 10) : 40);
      state.spectrumNumAccording = !!localStorage.getItem("spectrumNumAccording");
      state.spectrumLineWidth = Math.min(Math.max(Number(localStorage.getItem("spectrumLineWidth") || 10) || 10, 1), 40);
      state.spectrumLineGap = Math.min(Math.max(Number(localStorage.getItem("spectrumLineGap") || 10) || 10, 0), 40);
    },
    spectrumColorIndex(state, value) {
      state.spectrumColorIndex = value;
      localStorage.setItem("spectrumColorIndex", value);
    },
    addSpectrumColorData(state, data) {
      state.spectrumColorData.push({
        id: data.id,
        position: Math.min(Math.max(Number(data.position), 0), 1) || 0,
        color: data.color.map((color, index) => {
          if (index > 2) return Math.min(Math.max(color, 0), 1) || 0;
          return Math.min(Math.max(Math.round(color), 0), 255) || 0;
        }),
      });
      state.spectrumColorData.sort((a, b) => a.position - b.position);
      localStorage.setItem("spectrumColorData", JSON.stringify(state.spectrumColorData.map(item => {
        delete item.id;
        return item;
      })));
    },
    removeSpectrumColorData(state, id) {
      state.spectrumColorData.splice(state.spectrumColorData.findIndex(item => item.id === id), 1);
      state.spectrumColorData.sort((a, b) => a.position - b.position);
      localStorage.setItem("spectrumColorData", JSON.stringify(state.spectrumColorData.map(item => {
        delete item.id;
        return item;
      })));
    },
    spectrumColorData(state, colorData) {
      state.spectrumColorData = colorData.map((item, index) => ({
        id: index,
        position: Math.min(Math.max(Number(item.position), 0), 1) || 0,
        color: item.color.map((color, index) => {
          if (index > 2) return Math.min(Math.max(color, 0), 1) || 0;
          return Math.min(Math.max(Math.round(color), 0), 255) || 0;
        }),
      }));
      localStorage.setItem("spectrumColorData", JSON.stringify(state.spectrumColorData.map(item => {
        delete item.id;
        return item;
      })));
    },
    spectrumColorDataFromId(state, data) {
      let colorData = state.spectrumColorData.find(item => item.id === data.id);
      if (!isNaN(Number(data.data.position))) colorData.position = Math.min(Math.max(Number(data.data.position), 0), 1) || 0;
      if (data.data.color) data.data.color.forEach((color, index) => {
        colorData.color[index] = Math.min(Math.max(color, 0), index > 2 ? 1 : 255) || 0;
      });
      state.spectrumColorData.sort((a, b) => a.position - b.position);
      localStorage.setItem("spectrumColorData", JSON.stringify(state.spectrumColorData.map(item => ({
        color: item.color,
        position: item.position,
      }))));
    },
    spectrumLineLength(state, value) {
      state.spectrumLineLength = value;
      localStorage.setItem("spectrumLineLength", value);
    },
    spectrumLineNum(state, value) {
      state.spectrumLineNum = value;
      localStorage.setItem("spectrumLineNum", value);
    },
    spectrumNumAccording(state, value) {
      state.spectrumNumAccording = !!value;
      localStorage.setItem("spectrumNumAccording", !!value);
    },
    spectrumLineWidth(state, value) {
      state.spectrumLineWidth = value;
      localStorage.setItem("spectrumLineWidth", value);
    },
    spectrumLineGap(state, value) {
      state.spectrumLineGap = value;
      localStorage.setItem("spectrumLineGap", value);
    },
  },
  actions: {
    // 初始化
    create(context) {
      context.commit("init");
    },
    changeSpectrumColorIndex(context, value) {
      if (spectrumColorOptions[value]) context.commit("spectrumColorIndex", value);
    },
    addSpectrumColorData(context, data) {
      context.commit("addSpectrumColorData", data);
    },
    removeSpectrumColorData(context, id) {
      context.commit("removeSpectrumColorData", id);
    },
    changeSpectrumColorData(context, data) {
      if (data && data.length > 1) context.commit("spectrumColorData", data);
    },
    changeSpectrumColorDataFromId(context, data) {
      if (context.state.spectrumColorData.find(item => item.id === data.id)) context.commit("spectrumColorDataFromId", data);
    },
    changeSpectrumLineLength(context, value) {
      if (value >= 0 && value <= 1) context.commit("spectrumLineLength", value);
    },
    changeSpectrumLineNum(context, value) {
      if (value >= 0 && value <= (window.innerWidth >= 768 ? Math.round(window.innerWidth / 10) : 40)) context.commit("spectrumLineNum", value);
    },
    toggleSpectrumNumAccording(context) {
      context.commit("spectrumNumAccording", !context.state.spectrumNumAccording);
    },
    changeSpectrumLineWidth(context, value) {
      if (value >= 0 && value <= 40) context.commit("spectrumLineWidth", value);
    },
    changeSpectrumLineGap(context, value) {
      if (value >= 0 && value <= 40) context.commit("spectrumLineGap", value);
    },
  },
  modules: {},
};