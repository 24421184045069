<template>
  <div class="bg-effect">
    <div class="settings-row">
      <div class="settings-label">背景特效：</div>
      <div class="settings-value options-list">
        <PuiButton
          v-for="(item, index) in bgEffectList"
          :key="item.key"
          :value="index === bgEffectIndex"
          @click="$store.dispatch('bgEffect/changeBgEffectIndex', index)"
        >
          {{ item.name }}
        </PuiButton>
      </div>
    </div>
    <div
      v-for="(item, index) in bgEffectList[bgEffectIndex].data.config"
      :key="item.key"
      class="settings-row"
      :class="{ 'settings-row-half': item.half }"
    >
      <div class="settings-label">{{ item.name }}：</div>
      <div class="settings-value">
        <component
          v-if="item.value !== undefined"
          :is="'Pui' + item.type"
          :value="item.value"
          v-bind="item.attr"
          @change="
            (value) =>
              $store.dispatch('bgEffect/changeDataConfigValue', {
                index,
                value,
              })
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import PuiButton from "@/components/pizza-ui/PuiButton";
import PuiCheckboxGroup from "@/components/pizza-ui/PuiCheckboxGroup";
import PuiColorSelector from "@/components/pizza-ui/PuiColorSelector";
import PuiRadioGroup from "@/components/pizza-ui/PuiRadioGroup";
import PuiSlider from "@/components/pizza-ui/PuiSlider";
export default {
  components: {
    PuiButton,
    PuiCheckboxGroup,
    PuiColorSelector,
    PuiRadioGroup,
    PuiSlider,
  },
  data() {
    return {};
  },
  computed: {
    bgEffectIndex() {
      return this.$store.state.bgEffect.bgEffectIndex;
    },
    bgEffectList() {
      return this.$store.state.bgEffect.bgEffectList;
    },
    Ctrl() {
      return this.$store.state.functionKey.Ctrl;
    },
    Shift() {
      return this.$store.state.functionKey.Shift;
    },
  },
  methods: {},
  watch: {
    "$route.query.BgEffect"(newValue) {
      if (newValue === undefined) return;
      if (this.bgEffectList[newValue]) {
        this.$store.dispatch("bgEffect/changeBgEffectIndex", newValue);
      }
      this.$root.removeQuery("BgEffect");
    },
  },
  mounted() {},
  beforeUnmount() {},
};
</script>

<style lang="scss" scoped>
.bg-effect {
  .bg-color-options {
    .options-list {
      flex-wrap: wrap;
      @media (min-width: 768px) {
        margin-top: -8px;
      }
      @media (max-width: 767px) {
        margin-top: -1.6vw;
      }
      .pui-button {
        @media (min-width: 768px) {
          margin-top: 8px;
          margin-left: 8px;
        }
        @media (max-width: 767px) {
          margin-top: 1.6vw;
          margin-left: 1.6vw;
        }
      }
    }
  }
}
</style>