<template>
  <canvas
    ref="canvas"
    class="background-canvas"
    :class="canvasClass"
    :width="backgroundWidth"
    :height="backgroundHeight"
  ></canvas>
</template>

<script>
export default {
  props: {
    bgEffect: Object,
  },
  data() {
    return {
      backgroundCanvas: null,
      backgroundCtx: null,
      backgroundWidth: 0,
      backgroundHeight: 0,
      moveX: -1,
      moveY: -1,
    };
  },
  computed: {
    canvasClass() {
      return (this.bgEffect?.key || "")
        .replace(/([A-Z])/g, "-$1")
        .toLowerCase()
        .slice(1);
    },
    params() {
      return {
        ctx: this.backgroundCtx,
        width: this.backgroundWidth,
        height: this.backgroundHeight,
        rs: this.$rs,
        moveX: this.moveX,
        moveY: this.moveY,
      };
    },
  },
  methods: {
    reset() {
      this.updateData();
      // 设置宽高
      this.backgroundWidth = this.backgroundCanvas.clientWidth;
      this.backgroundHeight = this.backgroundCanvas.clientHeight;
      // 调用当前动画对应的重置方法
      this.bgEffect.reset(this.params);
    },
    draw(interval) {
      this.updateData();
      // 清除画布
      this.backgroundCtx.clearRect(
        0,
        0,
        this.backgroundWidth,
        this.backgroundHeight
      );
      // 调用当前动画对应的绘画方法
      this.bgEffect.draw(interval, this.params);
    },
    updateData() {
      let data = this.bgEffect.data;
      data.config.forEach((item) => (data[item.key] = item.value));
    },
    mouseMove(event) {
      event.preventDefault();
      let rect = this.backgroundCanvas.getBoundingClientRect();
      this.moveX =
        ((event.clientX - rect.x) / rect.width) * this.backgroundWidth;
      this.moveY =
        ((event.clientY - rect.y) / rect.height) * this.backgroundHeight;
    },
    touchMove(event) {
      event.preventDefault();
      this.moveX = event.touches[0].clientX;
      this.moveY = event.touches[0].clientY;
    },
    moveEnd() {
      setTimeout(() => {
        this.moveX = -1;
        this.moveY = -1;
      }, 0);
    },
  },
  watch: {
    effectIndex() {
      this.reset();
    },
  },
  mounted() {
    this.backgroundCanvas = this.$refs.canvas;
    if (this.backgroundCanvas.getContext) {
      setTimeout(() => {
        this.backgroundCtx = this.backgroundCanvas.getContext("2d");
        this.reset();
        this.$windowResizeFuncAdd(this.reset);
        this.$animationFrameFuncAdd(this.draw);
        this.backgroundCanvas.addEventListener("mousemove", this.mouseMove);
        this.backgroundCanvas.addEventListener("touchmove", this.touchMove);
        this.backgroundCanvas.addEventListener("mouseleave", this.moveEnd);
        this.backgroundCanvas.addEventListener("touchend", this.moveEnd);
      }, 0);
    }
  },
  beforeUnmount() {
    this.$windowResizeFuncRemove(this.reset);
    this.$animationFrameFuncRemove(this.draw);
    this.backgroundCanvas.removeEventListener("mousemove", this.mouseMove);
    this.backgroundCanvas.removeEventListener("touchmove", this.touchMove);
    this.backgroundCanvas.removeEventListener("mouseleave", this.moveEnd);
    this.backgroundCanvas.removeEventListener("touchend", this.moveEnd);
  },
};
</script>

<style lang="scss" scoped>
.background-canvas {
  width: 100%;
  height: 100%;
  &.blur {
    @media (min-width: 768px) {
      filter: blur(2px);
    }
    @media (max-width: 767px) {
      filter: blur(0.3vw);
    }
  }
}
</style>