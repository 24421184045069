<template>
  <svg class="svg-logo" viewBox="0 0 2560 640">
    <path
      d="M60 570v-30h2440v30H60zM197.76 67.12c52.264 0 92.768 12.04 121.521 36.12 28.743 24.08 43.12 57.96 43.12 101.64 0 43.312-13.913 77-41.721 101.08-27.816 24.08-67.112 36.12-117.88 36.12h-79.52V500H60V67.12h137.76zm-7.28 216.16c33.967 0 60.287-6.808 78.96-20.44 18.663-13.623 28-32.943 28-57.96 0-25.384-9.337-44.887-28-58.52-18.673-13.624-44.993-20.44-78.96-20.44h-67.2v157.36h67.2zM484.756 134.88c-7.279 7.097-16.896 10.64-28.84 10.64-11.576 0-20.816-3.543-27.72-10.64-6.913-7.088-10.36-16.607-10.36-28.56 0-11.944 3.447-21.464 10.36-28.561 6.903-7.087 16.144-10.64 27.72-10.64 11.943 0 21.561 3.553 28.84 10.64 7.28 7.097 10.92 16.616 10.92 28.561 0 11.952-3.64 21.472-10.92 28.56zm2.52 57.12v308h-61.04V192h61.04zM791.916 239.04l-158.48 203.28h166.32V500h-251.44v-47.04l159.04-203.279h-156.24V192h240.801v47.04zM1077.513 239.04l-158.48 203.28h166.32V500h-251.44v-47.04l159.04-203.279h-156.24V192h240.801v47.04zM1151.43 484.32c-17.553-15.68-26.32-36.768-26.32-63.28 0-33.224 13.064-58.52 39.2-75.88 26.128-17.36 63.28-26.04 111.44-26.04h39.2v-19.6c0-19.04-5.696-34.064-17.08-45.08-11.393-11.008-26.601-16.521-45.641-16.521-24.64 0-48.536 11.017-71.68 33.04l-38.08-35.84c31.728-33.968 70-50.96 114.8-50.96 36.208 0 65.048 10.08 86.521 30.24 21.464 20.16 32.2 47.04 32.2 80.64V500h-61.04v-40.88h-3.36c-7.84 14.937-19.977 26.793-36.4 35.561-16.432 8.767-34.159 13.159-53.199 13.159-29.498 0-53.017-7.84-70.561-23.52zm121.24-36.96c12.127-4.848 22.207-12.407 30.24-22.68 8.023-10.265 12.04-22.864 12.04-37.801v-14.56h-29.681c-33.232 0-57.776 3.64-73.64 10.92-15.872 7.28-23.8 18.576-23.8 33.88 0 11.2 4.384 20.256 13.16 27.16 8.768 6.912 20.623 10.36 35.56 10.36 11.944.002 23.984-2.422 36.121-7.279zM1751.85 67.12V441.2h209.439V500h-272.72V67.12h63.281zM2080.009 67.12V500h-63.28V67.12h63.28zM2227.28 500H2164V67.12h61.6l208.32 316.4h2.8V67.12H2500V500h-59.92l-209.44-316.399h-3.359V500zM1415.99 500v-30h232.579v30H1415.99z"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.svg-logo {
  width: 4em;
  height: 1em;
  fill: currentColor;
}
</style>