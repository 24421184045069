let animationFrameFuncList = [];
let lastFrameTimestamp = new Date().getTime();

export const animationFrameFuncAdd = (func, repeatAllowed) => {
  let index = animationFrameFuncList.indexOf(func);
  if (repeatAllowed || index < 0) {
    return animationFrameFuncList.push(func);
  }
  return index;
};
export const animationFrameFuncRemove = (func) => {
  let index = animationFrameFuncList.indexOf(func);
  if (index > -1) {
    animationFrameFuncList.splice(index, 1);
    return index;
  }
  return false;
};

const comRequestAnimationFrame =
  window.requestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  window.oRequestAnimationFrame ||
  window.msRequestAnimationFrame ||
  ((callback) => setTimeout(callback, 1000 / 60));
const updateAnimationFrame = () => {
  let timestamp = new Date().getTime();
  let interval = (timestamp - lastFrameTimestamp) / 1000;
  animationFrameFuncList.forEach(item => item(interval, timestamp));
  lastFrameTimestamp = timestamp;
  comRequestAnimationFrame(updateAnimationFrame);
}
export const nextFrame = (callback) => !!comRequestAnimationFrame(callback);
updateAnimationFrame();