<template>
  <div class="pui-checkbox-group">
    <component
      v-for="(item, index) in option"
      :key="item.label || item"
      :is="'Pui' + type"
      :value="value.indexOf(item.value || index) > -1"
      @click="click(item.value || index)"
      >{{ item.label || item }}</component
    >
  </div>
</template>

<script>
import PuiButton from "@/components/pizza-ui/PuiButton";
import PuiCheckbox from "@/components/pizza-ui/PuiCheckbox";
export default {
  props: {
    value: Array,
    option: Array,
    type: {
      type: String,
      default: "Checkbox",
      validator: value => ["Checkbox", "Button"].indexOf(value) > -1,
    },
  },
  components: {
    PuiButton,
    PuiCheckbox,
  },
  data() {
    return {
      cValue: [],
    };
  },
  methods: {
    click(value) {
      let index = this.cValue.indexOf(value);
      if (index > -1) this.cValue.splice(index, 1);
      else this.cValue.push(value);
      this.$emit("change", this.cValue);
    },
  },
  watch: {
    value: {
      handler(newValue) {
        this.cValue = newValue;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.pui-checkbox-group {
  padding: 0 4px;
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
}
</style>