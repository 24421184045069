<template>
  <svg :class="'svg-' + name" viewBox="0 0 1024 1024">
    <component :is="name" :state="state" />
  </svg>
</template>

<script>
export default {
  components: {
    musicPaused: {
      template: `
        <path
          :d="
            state === true
              ? 'M320 64V960L640 736V288zM640 288V736L960 512V512z'
              : 'M192 64V960L320 960V64zM704 64V960L832 960V64z'
          "
          stroke-width="128"
        />
      `,
      props: {
        state: null,
      },
    },
    musicRound: {
      template: `
        <g>
          <path
            :d="
              state === true
                ? roundD
                : rectD
            "
            fill="none"
            :stroke-width="strokeWidth"
          />
        </g>
      `,
      props: {
        state: null,
      },
      data() {
        return {
          strokeWidth: 32,
          roundD: "",
          rectD: "",
        };
      },
      created() {
        let r = 512 + this.strokeWidth;
        let l = 512 - this.strokeWidth;
        let w = Math.pow(r * r - l * l, 0.5);
        let br = 51.2 - this.strokeWidth;
        this.roundD += `M${512 - l} ${512 - w}v0a${r} ${r} 0 0 1 ${l - w} ${
          w - l
        }h0h-${this.strokeWidth * 4}`;
        this.roundD += `M${512 + w} ${512 - l}h0a${r} ${r} 0 0 1 ${l - w} ${
          l - w
        }v0v-${this.strokeWidth * 4}`;
        this.roundD += `M${512 + l} ${512 + w}v0a${r} ${r} 0 0 1 ${w - l} ${
          l - w
        }h0h${this.strokeWidth * 4}`;
        this.roundD += `M${512 - w} ${512 + l}h0a${r} ${r} 0 0 1 ${w - l} ${
          w - l
        }v0v${this.strokeWidth * 4}`;
        this.rectD += `M${512 - l} ${512 - w}v${
          br + w - l
        }a${br} ${br} 0 0 1 ${br}-${br}h${l - w - br}h-${this.strokeWidth * 4}`;
        this.rectD += `M${512 + w} ${512 - l}h${
          l - w - br
        }a${br} ${br} 0 0 1 ${br} ${br}v${l - w - br}v-${this.strokeWidth * 4}`;
        this.rectD += `M${512 + l} ${512 + w}v${
          l - w - br
        }a${br} ${br} 0 0 1-${br} ${br}h${br + w - l}h${this.strokeWidth * 4}`;
        this.rectD += `M${512 - w} ${512 + l}h${
          br + w - l
        }a${br} ${br} 0 0 1-${br}-${br}v${br + w - l}v${this.strokeWidth * 4}`;
      },
    },
    musicSwitch: {
      template: `
        <path
          :d="
            state === 'prev'
              ? 'M128 128V896L64 896V128zM896 128V896L320 512V512z'
              : state === 'next'
                ? 'M128 128V896L704 512V512zM896 128V896L960 896V128z'
                : ''
          "
          stroke-width="128"
        />
      `,
      props: {
        state: null,
      },
    },
    musicVolume: {
      template: `
        <path
          d="M64 352H256L448 128V896L256 682H64z"
          stroke-width="128"
        />
        <path
          :d="data[state]"
          fill="none"
          stroke-width="64"
        />
      `,
      props: {
        state: null,
      },
      data() {
        return {
          data: {
            muted: "",
            0: "",
            1: "",
            2: "",
            3: "",
            plus: "",
            minus: "",
          },
        };
      },
      created() {
        let rs = [0, 192, 352, 512];
        let centerX = 448;
        let centerY = 512;
        let angle = Math.PI / 4;
        let sin = Math.sin(angle);
        let cos = Math.cos(angle);
        let Ds = rs.map((r) => {
          let dx = r * cos;
          let x = centerX + dx;
          let dy = r * sin;
          let y = centerY - dy;
          return `M${x} ${y}a${r} ${r} 0 0 1 ${r - dx} ${dy}a${r} ${r} 0 0 1 ${
            dx - r
          } ${dy}`;
        });
        this.data.muted = Ds[0];
        this.data.muted +=
          "M576 320a8192 8192 0 0 1 192 192a8192 8192 0 0 1 -192 192";
        this.data.muted +=
          "M960 320a8192 8192 0 0 1 -192 192a8192 8192 0 0 1 192 192";
        for (let i = 0; i < 4; i++) {
          this.data[i] = "";
          for (let j = 2; j > -1; j--) {
            this.data[i] += Ds[Math.max(i - j, 0)];
          }
        }
      },
    },
    musicPlayMode: {
      template: `
        <g fill="none" stroke-width="64">
          <path :d="data[state]" />
          <path
            d="M0 -128v256"
            :style="{ transform: \`translate3d(50%, 50%, 0) scale3d(\${state === 'loop' ? '1, 1' : '0, 0'}, 1)\` }"
          />
        </g>
      `,
      // <text x="0" y="768" font-size="465.454545" stroke-width="0" textLength="1024">&nbsp;1&nbsp;</text>
      props: {
        state: null,
      },
      data() {
        return {
          data: {
            order: "",
            list: "",
            single: "",
            loop: "",
            shuffle: "",
          },
        };
      },
      created() {
        let r = 256;
        this.data.order += `M32 ${512 - r}l${r} 0a${r} ${r} 0 0 1 0 0`;
        this.data.order += `l${960 - r * 2} 0a${r} ${r} 0 0 0 0 0l${r} 0`;
        this.data.order += `m-96-96l96 96l-96 96`;
        this.data.order += `M32 ${512 + r}l0 0l0 0`;
        this.data.order += `m0 0l${r} 0a${r} ${r} 0 0 0 0 0l${480 - r} 0`;
        this.data.order += `m0 0l${480 - r} 0a${r} ${r} 0 0 1 0 0l${r} 0`;
        this.data.order += `m-96-96l96 96l-96 96`;
        this.data.list += `M32 512l0 -64a${r} ${r} 0 0 1 ${r}-${r}`;
        this.data.list += `l${960 - r * 2} 0a${r} ${r} 0 0 0 0 0l${r} 0`;
        this.data.list += `m-96-96l96 96l-96 96`;
        this.data.list += `M128 ${480 + r}l-96 96l96 96`;
        this.data.list += `m-96-96l${960 - r} 0a${r} ${r} 0 0 0 ${r}-${r}l0 0`;
        this.data.list += `m0 0l0 0a${r} ${r} 0 0 1 0 0l0 -64`;
        this.data.list += `m0 0l0 0l0 0`;
        this.data.single += `M32 512l${r} 0a${r} ${r} 0 0 1 0 0`;
        this.data.single += `l${960 - r * 2} 0a${r} ${r} 0 0 0 0 0l${r} 0`;
        this.data.single += `m-96-96l96 96l-96 96`;
        this.data.single += `M32 512l0 0l0 0`;
        this.data.single += `m0 0l${r} 0a${r} ${r} 0 0 0 0 0l${480 - r} 0`;
        this.data.single += `m0 0l${480 - r} 0a${r} ${r} 0 0 1 0 0l${r} 0`;
        this.data.single += `m-96-96l96 96l-96 96`;
        this.data.loop = this.data.list;
        let sin = Math.sin(Math.PI / 3);
        let tan = Math.tan(Math.PI / 3);
        let dx = [480 - r / sin, (r / 2) * tan, r / 2 / tan];
        this.data.shuffle += `M32 ${512 - r}l${dx[0]} 0`;
        this.data.shuffle += `a${r} ${r} 0 0 1 ${dx[1]} ${r / 2}`;
        this.data.shuffle += `l${dx[2] * 2} ${r}`;
        this.data.shuffle += `a${r} ${r} 0 0 0 ${dx[1]} ${r / 2}l${dx[0]} 0`;
        this.data.shuffle += `m-96-96l96 96l-96 96`;
        this.data.shuffle += `M32 ${512 + r}l0 0l0 0`;
        this.data.shuffle += `m0 0l${dx[0]} 0`;
        this.data.shuffle += `a${r} ${r} 0 0 0 ${dx[1]}-${r / 2}`;
        this.data.shuffle += `l0 0`;
        this.data.shuffle += `m${dx[2] * 2}-${r}`;
        this.data.shuffle += `l0 0`;
        this.data.shuffle += `a${r} ${r} 0 0 1 ${dx[1]}-${r / 2}l${dx[0]} 0`;
        this.data.shuffle += `m-96-96l96 96l-96 96`;
      },
    },
    settings: {
      template: `
        <g fill="none" :stroke-width="strokeWidth">
          <circle cx="512" cy="512" r="128" />
          <path
            v-for="i in 8"
            :key="i"
            :transform="'rotate(' + i * 45 + ' 512 512)'"
            :d="outerD"
          />
          <path
            v-for="i in 8"
            :key="i"
            :transform="'rotate(' + (i * 45 - 22.5) + ' 512 512)'"
            :d="innerD"
          />
        </g>
      `,
      props: {
        state: null,
      },
      data() {
        return {
          strokeWidth: 64,
          outerD: "",
          innerD: "",
        };
      },
      created() {
        let R = 512 - this.strokeWidth / 2;
        let angle = Math.PI / 16;
        let sin = Math.sin(angle);
        let cos = Math.cos(angle);
        let dx = (R * sin * cos) / (1 + sin);
        let x = 512 - dx;
        let y = 512 - (R * cos * cos) / (1 + sin);
        let r = (R * sin) / (1 + sin);
        this.outerD = `M${x} ${y}a${r} ${r} 0 1 1 ${dx * 2} 0`;
        this.innerD = `M${x} ${y}a${r} ${r} 0 0 0 ${dx * 2} 0`;
      },
    },
    expandMenu: {
      template: `<path :d="data[state]" fill="none" stroke-width="64" />`,
      props: {
        state: null,
      },
      data() {
        return {
          data: {
            normal:
              "M32 128L992 128M32 512L992 512M32 512L992 512M32 896L992 896",
            left: "M32 512L512 128M512 512L992 128M512 512L992 896M32 512L512 896",
            right:
              "M512 128L992 512M32 128L512 512M32 896L512 512M512 896L992 512",
          },
        };
      },
    },
    music: {
      template: `
        <g fill="none" stroke-width="64">
          <ellipse
            transform="rotate(-45 512 512)"
            cx="512"
            cy="512"
            rx="160"
            ry="128"
          />
          <path
            d="M512 32A480 480 0 1 0 963.1752 347.7857M656.8915 480.1V54.3517A480 480 0 0 1 879.711 203.4538"
          />
          <path
            transform="rotate(-15 512 512)"
            d="M512 160A352 352 0 0 0 207.1083 335.9707M512 864A352 352 0 0 0 816.8917 688.0293"
          />
        </g>
      `,
      props: {
        state: null,
      },
    },
    close: {
      template: `
        <g fill="none" stroke-width="64">
          <circle cx="512" cy="512" r="480" />
          <path d="M320 320L704 704M320 704L704 320" />
        </g>
      `,
      props: {
        state: null,
      },
    },
  },
  props: {
    name: {
      type: String,
      default: "",
    },
    state: null,
  },
};
</script>

<style lang="scss" scoped>
[class^="svg-"] {
  width: 1em;
  height: 1em;
  vertical-align: middle;
  fill: currentColor;
  stroke: currentColor;
  stroke-linecap: round;
  stroke-linejoin: round;
  overflow: hidden;
}
</style>