import {
  effectList,
  bgEffect
} from "@/assets/js/bgEffect.js";

export default {
  namespaced: true,
  state: {
    bgEffectIndex: 1,
    bgEffectList: effectList.map((item, index) => new bgEffect(index)),
  },
  mutations: {
    bgEffectIndex(state, value) {
      state.bgEffectIndex = value;
    },
    dataConfigValue(state, data) {
      state.bgEffectList[data.effectIndex].data.config[data.index].value = data.value;
    },
  },
  actions: {
    create(context) {
      let index = Number(localStorage.getItem("bgEffectIndex"));
      if (context.state.bgEffectList[index]) context.commit("bgEffectIndex", index);
      context.state.bgEffectList.forEach((effect, effectIndex) => {
        effect.data.config.forEach((config, configIndex) => {
          let value = JSON.parse(localStorage.getItem(`bgEffect_${effect.key}_${config.key}`));
          if (value === undefined || value === null) {
            value = JSON.parse(JSON.stringify(config.default));
          } else {
            value = config.fliter(value);
            if (value === undefined) {
              value = JSON.parse(JSON.stringify(config.default));
              localStorage.removeItem(`bgEffect_${effect.key}_${config.key}`);
            }
          }
          context.commit("dataConfigValue", {
            effectIndex,
            index: configIndex,
            value,
          });
        });
      });
    },
    changeBgEffectIndex(context, value) {
      if (!context.state.bgEffectList[value]) return;
      context.commit("bgEffectIndex", value);
      localStorage.setItem("bgEffectIndex", value);
    },
    changeDataConfigValue(context, data) {
      if (!context.state.bgEffectList[context.state.bgEffectIndex].data.config[data.index]) return;
      data.effectIndex = context.state.bgEffectIndex;
      context.commit("dataConfigValue", data);
      localStorage.setItem(`bgEffect_${context.state.bgEffectList[context.state.bgEffectIndex].key}_${context.state.bgEffectList[context.state.bgEffectIndex].data.config[data.index].key}`, JSON.stringify(data.value));
    },
  },
  modules: {},
};