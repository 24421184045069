import {
  createApp
} from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VConsole from "vconsole";

// 开发环境下
if (process.env.NODE_ENV === "development") {
  // 开启移动端debug控制台
  new VConsole();
  setTimeout(() => {
    document.getElementById("__vconsole").classList.add("no-animate");
  }, 0);
}

let app = createApp(App);

// 跨浏览器兼容性全局变量
window.AudioContext = window.AudioContext || window.webkitAudioContext;

// 窗口变化相关方法
import {
  responseSize,
  toVw,
  fromVw,
  windowResizeFuncAdd,
  windowResizeFuncRemove,
} from "@/utils/responseSize";
app.config.globalProperties.$rs = responseSize;
app.config.globalProperties.$tv = toVw;
app.config.globalProperties.$fv = fromVw;
app.config.globalProperties.$windowResizeFuncAdd = windowResizeFuncAdd;
app.config.globalProperties.$windowResizeFuncRemove = windowResizeFuncRemove;
// 窗口变化修改缩放
let setMetaViewportScale = () => {
  let meta = document.getElementById("metaViewport");
  let contentData = meta.content
    .split(",")
    .map((item) => item.split("="));
  let scale = contentData.find((item) => item[0] === "initial-scale")[1];
  let realWidth = window.innerHeight * scale;
  if (realWidth <= 639) {
    scale = realWidth / 640;
  } else {
    scale = 1;
  }
  contentData.find(item => item[0] === "initial-scale")[1] = scale;
  meta.content = contentData.map(item => item.join("=")).join(",");
  app.config.globalProperties.$windowWidth = window.innerWidth;
  app.config.globalProperties.$windowHeight = window.innerHeight;
};
setMetaViewportScale();
window.addEventListener("resize", setMetaViewportScale);

// 防止部分函数报错的tryCatch方法
import "@/utils/tryCatch";

// 帧动画相关方法
import {
  animationFrameFuncAdd,
  animationFrameFuncRemove,
  nextFrame,
} from "@/utils/animationFrame";
app.config.globalProperties.$animationFrameFuncAdd = animationFrameFuncAdd;
app.config.globalProperties.$animationFrameFuncRemove = animationFrameFuncRemove;
app.config.globalProperties.$nextFrame = nextFrame;

// 全局遮罩层mask相关变量
import {
  maskElement,
} from "@/utils/maskElement";
app.config.globalProperties.$mask = maskElement;

// 12月13日纪念南京大屠杀黑白效果
let date = new Date();
if (date.getMonth() === 11 && date.getDate() === 13) {
  document.getElementById("app").style.filter = "grayscale(100%)";
}

app.use(router);
app.use(store);
app.mount("body");