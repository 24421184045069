export const effectList = [{
  name: "滚动代码",
  key: "ScrollCode",
  data() {
    return {
      timestamp: 0,
      columnNum: 0,
      codeLength: 0,
      codeData: [],
      createSingleCode,
      config: this.config,
    }
  },
  config: [{
    key: "color",
    name: "代码颜色",
    type: "ColorSelector",
    default: {
      R: 0,
      G: 255,
      B: 0,
    },
    fliter(value) {
      if (value.R === undefined && value.G === undefined && value.B === undefined) return undefined;
      ["R", "G", "B"].forEach(key => {
        value[key] = Number(value[key]);
        value[key] = isNaN(value[key]) ? this.value[key] : Math.min(Math.max(value[key], 0), 255);
      });
      return value;
    },
    attr: {
      simple: true,
    },
  }, {
    key: "textType",
    name: "字符类型",
    type: "RadioGroup",
    default: 0,
    fliter(value) {
      value = Number(value);
      if (isNaN(value)) return undefined;
      return Math.min(Math.max(value, 0), this.attr.option.length);
    },
    attr: {
      option: ["0/1", "乱码", "中文"],
    },
  }, {
    key: "columnWidth",
    name: "每列宽度",
    type: "Slider",
    half: true,
    default: 48,
    fliter(value) {
      value = Number(value);
      if (isNaN(value)) return undefined;
      let stepLength = (this.attr.max - this.attr.min) / this.attr.step;
      value = Math.min(Math.max(value, this.attr.min), this.attr.max) - this.attr.min;
      return this.attr.min + Math.round(value / stepLength) * stepLength;
    },
    attr: {
      min: 48,
      max: 144,
      step: 96,
      showValue: true,
      responseSize: 0,
    },
  }, {
    key: "codeHeight",
    name: "代码高度",
    type: "Slider",
    half: true,
    default: 48,
    fliter(value) {
      value = Number(value);
      if (isNaN(value)) return undefined;
      let stepLength = (this.attr.max - this.attr.min) / this.attr.step;
      value = Math.min(Math.max(value, this.attr.min), this.attr.max) - this.attr.min;
      return this.attr.min + Math.round(value / stepLength) * stepLength;
    },
    attr: {
      min: 48,
      max: 144,
      step: 96,
      showValue: true,
      responseSize: 0,
    },
  }, {
    key: "scrollSpeed",
    name: "滚动速度",
    type: "Slider",
    range: true,
    default: [12, 48],
    fliter(value) {
      if (!value.map) return undefined;
      return value.map((item, index) => {
        item = Number(item);
        if (isNaN(item)) return this.value[index];
        let stepLength = (this.attr.max - this.attr.min) / this.attr.step;
        item = Math.min(Math.max(item, this.attr.min), this.attr.max) - this.attr.min;
        return this.attr.min + Math.round(item / stepLength) * stepLength;
      })
    },
    attr: {
      min: 6,
      max: 54,
      step: 48,
      range: true,
      showValue: value => value.map(item => item.toFixed()).join(" - ") + "字符/s",
    },
  }],
  reset({
    width,
    height,
    rs,
  }) {
    // 设置参数
    let columnNum = Math.ceil(width / rs(this.data.columnWidth));
    let codeLength = Math.ceil(height / rs(this.data.codeHeight));
    // 创建代码数据
    let codeData = [];
    for (let i = 0; i < columnNum; i++) {
      let x = width / 2 - rs((columnNum - 1) * this.data.columnWidth / 2 - i * this.data.columnWidth);
      let y = Math.random() * height;
      let scrollSpeed = Math.random();
      let textArray = [];
      for (let j = 0; j < codeLength; j++) {
        if (Math.random() < (textArray[j - 1] !== "" ? 0.9 : 0.1)) {
          textArray[j] = this.data.createSingleCode(this.data.textType);
        } else {
          textArray[j] = "";
        }
        if (textArray[j] !== "") {
          codeData.push({
            x,
            y: y + j * rs(this.data.codeHeight),
            scrollSpeed,
            text: textArray[j],
          });
        }
      }
    }
    this.data.columnNum = columnNum;
    this.data.codeLength = codeLength;
    this.data.codeData = codeData;
  },
  draw(interval, {
    ctx,
    width,
    height,
    rs,
  }) {
    let data = this.data;
    let codeHeight = data.codeHeight;
    let columnNum = Math.ceil(width / rs(data.columnWidth));
    let codeLength = Math.ceil(height / rs(codeHeight));
    if (columnNum !== data.columnNum || codeLength !== data.codeLength) this.reset({
      width,
      height,
      rs,
    });
    ctx.font = `bold ${rs(codeHeight / 2)}px Helvetica`;
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    let color = data.color;
    ctx.fillStyle = `rgba(${[color.R, color.G, color.B]}, 0.333333)`;
    // 绘制文字
    let scrollSpeeds = data.scrollSpeed;
    let scrollSpeed0 = scrollSpeeds[0];
    let scrollSpeedGap = scrollSpeeds[1] - scrollSpeed0;
    let maxHeight = height + rs(codeHeight / 2);
    let heightSpan = rs(data.codeLength * codeHeight);
    data.codeData.forEach((item) => {
      let scrollSpeed = rs(scrollSpeed0 + item.scrollSpeed * scrollSpeedGap);
      item.y += scrollSpeed * interval;
      if (item.y > maxHeight) {
        item.y -= heightSpan;
      }
      if (Math.random() < 0.1) {
        item.text = data.createSingleCode(data.textType);
      }
      ctx.fillText(item.text, item.x, item.y);
    });
  },
}, {
  name: "代码雨",
  key: "CodeRain",
  data() {
    return {
      timestamp: 0,
      columnNum: 0,
      codeLength: 0,
      codeData: [],
      createSingleCode,
      config: this.config,
    }
  },
  config: [{
    key: "color",
    name: "代码颜色",
    type: "ColorSelector",
    default: {
      R: 0,
      G: 255,
      B: 0,
    },
    fliter(value) {
      if (value.R === undefined && value.G === undefined && value.B === undefined) return undefined;
      ["R", "G", "B"].forEach(key => {
        value[key] = Number(value[key]);
        value[key] = isNaN(value[key]) ? this.value[key] : Math.min(Math.max(value[key], 0), 255);
      });
      return value;
    },
    attr: {
      simple: true,
    },
  }, {
    key: "textType",
    name: "字符类型",
    type: "RadioGroup",
    default: 0,
    fliter(value) {
      value = Number(value);
      if (isNaN(value)) return undefined;
      return Math.min(Math.max(value, 0), this.attr.option.length);
    },
    attr: {
      option: ["0/1", "乱码", "中文"],
    },
  }, {
    key: "columnWidth",
    name: "每列宽度",
    type: "Slider",
    half: true,
    default: 24,
    fliter(value) {
      value = Number(value);
      if (isNaN(value)) return undefined;
      let stepLength = (this.attr.max - this.attr.min) / this.attr.step;
      value = Math.min(Math.max(value, this.attr.min), this.attr.max) - this.attr.min;
      return this.attr.min + Math.round(value / stepLength) * stepLength;
    },
    attr: {
      min: 24,
      max: 72,
      step: 48,
      showValue: true,
      responseSize: 0,
    },
  }, {
    key: "codeHeight",
    name: "代码高度",
    type: "Slider",
    half: true,
    default: 24,
    fliter(value) {
      value = Number(value);
      if (isNaN(value)) return undefined;
      let stepLength = (this.attr.max - this.attr.min) / this.attr.step;
      value = Math.min(Math.max(value, this.attr.min), this.attr.max) - this.attr.min;
      return this.attr.min + Math.round(value / stepLength) * stepLength;
    },
    attr: {
      min: 24,
      max: 72,
      step: 48,
      showValue: true,
      responseSize: 0,
    },
  }, {
    key: "scrollSpeed",
    name: "滚动速度",
    type: "Slider",
    default: [8, 16],
    fliter(value) {
      if (!value.map) return undefined;
      return value.map((item, index) => {
        item = Number(item);
        if (isNaN(item)) return this.value[index];
        let stepLength = (this.attr.max - this.attr.min) / this.attr.step;
        item = Math.min(Math.max(item, this.attr.min), this.attr.max) - this.attr.min;
        return this.attr.min + Math.round(item / stepLength) * stepLength;
      })
    },
    attr: {
      min: 4,
      max: 20,
      step: 16,
      range: true,
      showValue: value => value.map(item => item.toFixed()).join(" - ") + "字符/s",
    },
  }],
  reset({
    width,
    height,
    rs,
  }) {
    // 设置参数
    let columnNum = Math.ceil(width / rs(this.data.columnWidth));
    let codeLength = Math.ceil(height / rs(this.data.codeHeight));
    // 去掉多余的行数据
    this.data.codeData.splice(columnNum);
    for (let i = 0; i < columnNum; i++) {
      let column = this.data.codeData[i];
      if (!column) {
        // 创建新的行
        column = {
          index: Math.random() * codeLength,
          scrollSpeed: Math.random(),
          codeList: [],
        };
        this.data.codeData[i] = column;
      }
      // 去掉多余的字符数据
      column.codeList.splice(codeLength);
      for (let j = 0; j < codeLength; j++) {
        if (column.codeList[j] === undefined) {
          // 创建新的字符
          column.codeList[j] = this.data.createSingleCode(this.data.textType);
        }
      }
    }
    this.data.columnNum = columnNum;
    this.data.codeLength = codeLength;
  },
  draw(interval, {
    ctx,
    width,
    height,
    rs,
  }) {
    // let a = new Date().valueOf();
    let data = this.data;
    let columnWidth = data.columnWidth;
    let codeHeight = data.codeHeight;
    let rsCodeHeight = rs(codeHeight);
    let columnNum = data.columnNum;
    let codeLength = data.codeLength;
    if (Math.ceil(width / rs(columnWidth)) !== data.columnNum || Math.ceil(height / rsCodeHeight) !== data.codeLength) this.reset({
      width,
      height,
      rs,
    });
    ctx.font = `bold ${rsCodeHeight}px Helvetica`;
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    // 绘制文字
    let y = (height - rs((codeLength - 1) * codeHeight)) / 2;
    let codeData = data.codeData;
    let scrollSpeeds = data.scrollSpeed;
    let scrollSpeed0 = scrollSpeeds[0];
    let scrollSpeedGap = scrollSpeeds[1] - scrollSpeed0;
    let color = data.color;
    let colorValue = [color.R, color.G, color.B] + "";
    for (let i = 0; i < codeData.length; i++) { // 绘制每一行
      let column = codeData[i];
      let x = (width - rs((columnNum - 1 - i * 2) * columnWidth)) / 2;
      let scrollSpeed = rs(scrollSpeed0 + column.scrollSpeed * scrollSpeedGap);
      column.index += (scrollSpeed * interval) % codeLength;
      let codeList = column.codeList;
      let codeIndex = column.index;
      for (let j = 0; j < codeList.length; j++) { // 绘制每一个
        let index = Math.floor(j + codeIndex) % codeLength;
        let code = codeList[index];
        // 计算透明度
        let alpha;
        if (j) {
          alpha = ((j / codeLength - 1) * 32) / scrollSpeed + 1;
          if (code !== "" && alpha <= 0) code = "";
        } else {
          alpha = codeIndex % 1;
        }
        // 判断是否需要生成新字符
        if (code === "" && alpha > 0) code = data.createSingleCode(data.textType);
        // 绘制字符
        if (code !== "" && alpha > 0) {
          ctx.fillStyle = `rgba(${colorValue}, ${Math.max(alpha / 3, 0)})`;
          ctx.fillText(code, x, y + index * rsCodeHeight);
        }
      }
    }
    // console.log(new Date().valueOf() - a);
  },
}, {
  name: "引力小球",
  key: "GravityBall",
  data() {
    return {
      timestamp: 0,
      ballData: [],
      changeSpeed,
      config: this.config,
    }
  },
  config: [{
    key: "color",
    name: "小球颜色",
    type: "ColorSelector",
    default: {
      R: 0,
      G: 0,
      B: 255,
    },
    fliter(value) {
      if (value.R === undefined && value.G === undefined && value.B === undefined) return undefined;
      ["R", "G", "B"].forEach(key => {
        value[key] = Number(value[key]);
        value[key] = isNaN(value[key]) ? this.value[key] : Math.min(Math.max(value[key], 0), 255);
      });
      return value;
    },
    attr: {
      simple: true,
    },
  }, {
    key: "ballNum",
    name: "小球数量",
    type: "Slider",
    half: true,
    default: 25,
    fliter(value) {
      value = Number(value);
      if (isNaN(value)) return undefined;
      let stepLength = (this.attr.max - this.attr.min) / this.attr.step;
      value = Math.min(Math.max(value, this.attr.min), this.attr.max) - this.attr.min;
      return this.attr.min + Math.round(value / stepLength) * stepLength;
    },
    attr: {
      min: 5,
      max: 100,
      step: 95,
      showValue: value => value.toFixed() + "个",
    },
  }, {
    key: "ballRadius",
    name: "小球半径",
    type: "Slider",
    half: true,
    default: 15,
    fliter(value) {
      value = Number(value);
      if (isNaN(value)) return undefined;
      let stepLength = (this.attr.max - this.attr.min) / this.attr.step;
      value = Math.min(Math.max(value, this.attr.min), this.attr.max) - this.attr.min;
      return this.attr.min + Math.round(value / stepLength) * stepLength;
    },
    attr: {
      min: 5,
      max: 100,
      step: 95,
      showValue: true,
      responseSize: 0,
    },
  }, {
    key: "gravitySize",
    name: "引力大小",
    type: "Slider",
    half: true,
    default: 100,
    fliter(value) {
      value = Number(value);
      if (isNaN(value)) return undefined;
      let stepLength = (this.attr.max - this.attr.min) / this.attr.step;
      value = Math.min(Math.max(value, this.attr.min), this.attr.max) - this.attr.min;
      return this.attr.min + Math.round(value / stepLength) * stepLength;
    },
    attr: {
      min: 0,
      max: 500,
      step: 100,
      showValue: value => value + "/s²",
      responseSize: 0,
    },
  }, {
    key: "dragSize",
    name: "阻力大小",
    type: "Slider",
    half: true,
    default: 20,
    fliter(value) {
      value = Number(value);
      if (isNaN(value)) return undefined;
      let stepLength = (this.attr.max - this.attr.min) / this.attr.step;
      value = Math.min(Math.max(value, this.attr.min), this.attr.max) - this.attr.min;
      return this.attr.min + Math.round(value / stepLength) * stepLength;
    },
    attr: {
      min: 0,
      max: 100,
      step: 100,
      showValue: value => value + "/s²",
      responseSize: 0,
    },
  }, {
    key: "gravityRange",
    name: "引力范围",
    type: "Slider",
    half: true,
    default: 200,
    fliter(value) {
      value = Number(value);
      if (isNaN(value)) return undefined;
      let stepLength = (this.attr.max - this.attr.min) / this.attr.step;
      value = Math.min(Math.max(value, this.attr.min), this.attr.max) - this.attr.min;
      return this.attr.min + Math.round(value / stepLength) * stepLength;
    },
    attr: {
      min: 50,
      max: 1000,
      step: 95,
      showValue: true,
      responseSize: 0,
    },
  }, {
    key: "speedRange",
    name: "速度范围",
    type: "Slider",
    half: true,
    default: [50, 200],
    fliter(value) {
      if (!value.map) return undefined;
      return value.map((item, index) => {
        item = Number(item);
        if (isNaN(item)) return this.value[index];
        let stepLength = (this.attr.max - this.attr.min) / this.attr.step;
        item = Math.min(Math.max(item, this.attr.min), this.attr.max) - this.attr.min;
        return this.attr.min + Math.round(item / stepLength) * stepLength;
      });
    },
    attr: {
      min: 0,
      max: 1000,
      step: 100,
      range: true,
      showValue: value => value + "/s",
      responseSize: 0,
    },
  }, {
    key: "other",
    name: "其他设置",
    type: "CheckboxGroup",
    default: [0, 1],
    fliter(value) {
      if (!value.filter) return undefined;
      return value.filter(item => {
        return item >= 0 && item < this.attr.option.length;
      });
    },
    attr: {
      option: ["小球碰撞", "鼠标吸引", "显示引力线条", "显示小球圆心"],
    },
  }],
  reset({
    width,
    height,
  }) {
    // 去掉多余的小球对象
    this.data.ballData.splice(this.data.ballNum);
    for (let i = 0; i < this.data.ballNum; i++) {
      let ball = this.data.ballData[i];
      if (!ball) {
        // 创建新的小球
        ball = {
          x: Math.random() * width,
          y: Math.random() * height,
          speed: this.data.speedRange[0],
          angle: Math.random() * 2 * Math.PI,
        };
        this.data.ballData[i] = ball;
      }
    }
  },
  draw(interval, {
    ctx,
    width,
    height,
    rs,
    moveX,
    moveY,
  }) {
    let data = this.data;
    let ballData = data.ballData;
    let ballDataLength = ballData.length;
    if (data.ballNum !== ballDataLength) this.reset({
      width,
      height,
    });
    ctx.lineWidth = rs(1);
    let color = data.color;
    let colorValue = [color.R, color.G, color.B] + "";
    ctx.strokeStyle = `rgba(${colorValue}, 0.5)`;
    ctx.fillStyle = `rgba(${colorValue}, 0.5)`;
    let ballRadius = rs(data.ballRadius);
    let other = data.other;
    // 判定小球碰撞
    if (other.indexOf(0) > -1) {
      for (let i = 0; i < ballDataLength - 1; i++) {
        for (let j = i + 1; j < ballDataLength; j++) {
          let ball1 = ballData[i];
          let ball1X = ball1.x;
          let ball1Y = ball1.y;
          let ball2 = ballData[j];
          let ball2X = ball2.x;
          let ball2Y = ball2.y;
          // 判定距离
          if (Math.sqrt(Math.pow(ball1X - ball2X, 2) + Math.pow(ball1Y - ball2Y, 2)) <= ballRadius * 2) {
            // 计算撞击力度
            let angle1 = Math.atan2(ball2Y - ball1Y, ball2X - ball1X);
            let angle2 = Math.atan2(ball1Y - ball2Y, ball1X - ball2X);
            let speedAngle1 = Math.abs(ball1.angle - angle1) % (Math.PI * 2);
            if (speedAngle1 > Math.PI) speedAngle1 = Math.PI * 2 - speedAngle1;
            let speedAngle2 = Math.abs(ball2.angle - angle2) % (Math.PI * 2);
            if (speedAngle2 > Math.PI) speedAngle2 = Math.PI * 2 - speedAngle2;
            if (speedAngle1 < Math.PI / 2 || speedAngle2 < Math.PI / 2) {
              let speed = (ball1.speed * Math.cos(speedAngle1) + ball2.speed * Math.cos(speedAngle2));
              // 改变速度
              data.changeSpeed(ball1, {
                speed,
                angle: angle2,
              });
              data.changeSpeed(ball2, {
                speed,
                angle: angle1,
              });
            }
          }
        }
      }
    }
    // 鼠标吸引
    if (other.indexOf(1) > -1 && moveX >= 0 && moveY >= 0) {
      let speed = data.gravitySize * interval;
      ballData.forEach(item => {
        if (Math.sqrt(Math.pow(moveX - item.x, 2) + Math.pow(moveY - item.y, 2)) <= data.gravityRange) {
          data.changeSpeed(item, {
            speed,
            angle: Math.atan2(moveY - item.y, moveX - item.x),
          });
        }
      });
    }
    // 遍历小球
    ballData.forEach(item => {
      // 计算阻力
      let drag = data.dragSize * interval;
      // 限制速度范围
      let speedRange = data.speedRange;
      let minSpeed = speedRange[0];
      let maxSpeed = speedRange[1];
      let speed = item.speed;
      if (speed > minSpeed) {
        item.speed = Math.max(minSpeed, speed - drag);
        speed = item.speed;
        if (speed > maxSpeed) {
          item.speed = maxSpeed + (speed - maxSpeed) * Math.pow(0.1, interval);
          speed = item.speed;
        }
      } else {
        item.speed = Math.min(minSpeed, speed + drag);
        speed = item.speed;
      }
      // 计算小球位置
      let angle = item.angle;
      item.x += speed * Math.cos(angle) * interval;
      if (item.x < -ballRadius) item.x += width + ballRadius * 2;
      if (item.x > width + ballRadius) item.x -= width + ballRadius * 2;
      item.y += speed * Math.sin(angle) * interval;
      if (item.y < -ballRadius) item.y += height + ballRadius * 2;
      if (item.y > height + ballRadius) item.y -= height + ballRadius * 2;
      // 绘制小球
      ctx.beginPath();
      ctx.arc(item.x, item.y, ballRadius, 0, 2 * Math.PI);
      ctx.stroke();
    })
    // 绘制连线
    if (other.indexOf(2) > -1 && moveX >= 0 && moveY >= 0) {
      ballData.forEach(item => {
        let alpha = Math.sqrt(Math.min(Math.max(data.gravityRange - Math.sqrt(Math.pow(moveX - item.x, 2) + Math.pow(moveY - item.y, 2)), 0) / data.gravityRange, 1));
        ctx.strokeStyle = `rgba(${colorValue}, ${alpha / 2})`;
        ctx.beginPath();
        ctx.moveTo(moveX, moveY);
        ctx.lineTo(item.x, item.y);
        ctx.stroke();
      });
    }
    // 绘制圆心
    if (other.indexOf(3) > -1) {
      let radius = ballRadius / 10;
      let doublePI = 2 * Math.PI;
      ballData.forEach(item => {
        ctx.beginPath();
        ctx.arc(item.x, item.y, radius, 0, doublePI);
        ctx.fill();
      });
    }
  },
}];

function createSingleCode(textType) {
  let text;
  switch (textType) {
    case 0:
      text = Math.round(Math.random());
      break;
    case 1: {
      let messyCodeList = "`~·1!2@3#4$5%6^7&8*9(0)-_=+qQwWeErRtTyYuUiIoOpP[{]}\\|aAsSdDfFgGhHjJkKlL;:'\"zZxXcCvVbBnNmM,<.>/?";
      text = messyCodeList[Math.floor(Math.random() * messyCodeList.length)];
    }
    break;
  case 2: {
    text = eval(`"\\u${(Math.round(Math.random() * 20901) + 19968).toString(16)}"`);
  }
  break;
  default:
    break;
  }
  return text;
}

function changeSpeed(item, force) {
  let x = item.speed * Math.cos(item.angle) + force.speed * Math.cos(force.angle);
  let y = item.speed * Math.sin(item.angle) + force.speed * Math.sin(force.angle);
  item.speed = Math.sqrt(x * x + y * y);
  if (x || y) item.angle = Math.atan2(y, x);
}

export function bgEffect(index) {
  this.name = effectList[index].name;
  this.key = effectList[index].key;
  this.data = effectList[index].data();
  this.reset = effectList[index].reset;
  this.draw = effectList[index].draw;
}