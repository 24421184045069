<template>
  <div
    class="pui-color-selector"
    :class="{ 'pui-color-selector-simple': simple }"
  >
    <div class="pui-color-selector-canvases">
      <div
        class="pui-color-selector-rect"
        @mousedown.prevent="rectMousedown"
        @touchstart.prevent="rectTouchstart"
      >
        <canvas ref="rect" :width="rectWidth" :height="rectHeight"></canvas>
        <div
          class="selector-circle"
          :style="{
            borderColor:
              colorValue.RGB.R * 1 + colorValue.RGB.G * 3 > 640
                ? '#000'
                : '#fff',
            left: `${selectValue.x * 100}%`,
            bottom: `${selectValue.y * 100}%`,
          }"
        ></div>
      </div>
      <div
        class="pui-color-selector-bar"
        @mousedown.prevent="barMousedown"
        @touchstart.prevent="barTouchstart"
      >
        <canvas ref="bar" width="1" :height="barHeight"></canvas>
        <div
          class="selector-arrow"
          :style="{ bottom: `${selectValue.z * 100}%` }"
        ></div>
      </div>
    </div>
    <div class="pui-color-selector-form">
      <div
        class="pui-color-selector-form-group pui-color-selector-form-group-HSB"
      >
        <div class="pui-color-selector-form-row pui-color-selector-form-row-H">
          <PuiRadio
            :value="colorMode === 'HSB.H'"
            @click="colorModeChange('HSB.H')"
          />
          <PuiInput
            label="H"
            v-model:value="colorValue.HSB.H"
            :inputAttr="{
              type: 'number',
              min: 0,
              max: 360,
              step: 1,
              precision: 0,
            }"
            after="度"
            @changeEnd="
              (value) => {
                colorValue.HSB.H = value;
                inputValueChange(valueChangeHSB);
              }
            "
          />
        </div>
        <div
          v-for="key in ['S', 'B']"
          :key="key"
          class="pui-color-selector-form-row"
          :class="'pui-color-selector-form-row-' + key"
        >
          <PuiRadio
            :value="colorMode === `HSB.${key}`"
            @click="colorModeChange(`HSB.${key}`)"
          />
          <PuiInput
            :label="key"
            v-model:value="colorValue.HSB[key]"
            :inputAttr="{
              type: 'number',
              min: 0,
              max: 100,
              step: 1,
              precision: 0,
            }"
            after="%"
            @changeEnd="
              (value) => {
                colorValue.HSB[key] = value;
                inputValueChange(valueChangeHSB);
              }
            "
          />
        </div>
      </div>
      <div
        v-if="!simple"
        class="pui-color-selector-form-group pui-color-selector-form-group-Lab"
      >
        <div class="pui-color-selector-form-row pui-color-selector-form-row-L">
          <PuiInput
            label="L"
            v-model:value="colorValue.Lab.L"
            :inputAttr="{
              type: 'number',
              min: 0,
              max: 100,
              step: 1,
              precision: 0,
            }"
            @changeEnd="
              (value) => {
                colorValue.Lab.L = value;
                inputValueChange(valueChangeLab);
              }
            "
          />
        </div>
        <div
          v-for="key in ['a', 'b']"
          :key="key"
          class="pui-color-selector-form-row"
          :class="'pui-color-selector-form-row-' + key"
        >
          <PuiInput
            :label="key"
            v-model:value="colorValue.Lab[key]"
            :inputAttr="{
              type: 'number',
              min: -128,
              max: 127,
              step: 1,
              precision: 0,
            }"
            @changeEnd="
              (value) => {
                colorValue.Lab[key] = value;
                inputValueChange(valueChangeLab);
              }
            "
          />
        </div>
      </div>
      <div
        class="pui-color-selector-form-group pui-color-selector-form-group-RGB"
      >
        <div
          v-for="key in ['R', 'G', 'B']"
          :key="key"
          class="pui-color-selector-form-row"
          :class="'pui-color-selector-form-row-' + key"
        >
          <PuiRadio
            :value="colorMode === `RGB.${key}`"
            @click="colorModeChange(`RGB.${key}`)"
          />
          <PuiInput
            :label="key"
            v-model:value="colorValue.RGB[key]"
            :inputAttr="{
              type: 'number',
              min: 0,
              max: 255,
              step: 1,
              precision: 0,
            }"
            @changeEnd="
              (value) => {
                colorValue.RGB[key] = value;
                inputValueChange(valueChangeRGB);
              }
            "
          />
        </div>
      </div>
      <div
        v-if="!simple"
        class="pui-color-selector-form-group pui-color-selector-form-group-CMYK"
      >
        <div
          v-for="key in ['C', 'M', 'Y', 'K']"
          :key="key"
          class="pui-color-selector-form-row"
          :class="'pui-color-selector-form-row-' + key"
        >
          <PuiInput
            :label="key"
            v-model:value="colorValue.CMYK[key]"
            :inputAttr="{
              type: 'number',
              min: 0,
              max: 100,
              step: 1,
              precision: 0,
            }"
            after="%"
            @changeEnd="
              (value) => {
                colorValue.CMYK[key] = value;
                inputValueChange(valueChangeCMYK);
              }
            "
          />
        </div>
      </div>
      <div
        class="pui-color-selector-form-group pui-color-selector-form-group-HEX"
      >
        <div
          class="pui-color-selector-form-row pui-color-selector-form-row-HEX"
        >
          <PuiInput
            label="#"
            v-model:value="colorValue.HEX"
            :inputAttr="{ type: 'text', maxlength: 6 }"
            :filter="(value) => (value.match(/[0-9a-f]/gi) || []).join('')"
            @changeEnd="
              (value) => {
                colorValue.HEX = value;
                inputValueChange(valueChangeHEX);
              }
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  HSB2RGB,
  RGB2HSB,
  Lab2RGB,
  RGB2Lab,
  CMYK2RGB,
  RGB2CMYK,
  HEX2RGB,
  RGB2HEX,
} from "@/utils/colorConversion.js";

import PuiRadio from "./PuiRadio";
import PuiInput from "./PuiInput";
export default {
  components: {
    PuiRadio,
    PuiInput,
  },
  props: {
    value: Object,
    simple: Boolean,
  },
  data() {
    return {
      rectCanvas: null,
      rectWidth: 100,
      rectHeight: 100,
      rectCtx: null,
      barCanvas: null,
      barHeight: 360,
      barCtx: null,
      holding: "",
      holdDebounce: false,
      touchId: null,
      selectValue: {
        x: 0,
        y: 0,
        z: 0,
      },
      colorValue: {
        HSB: {
          H: 0,
          S: 0,
          B: 100,
        },
        Lab: {
          L: 0,
          a: 0,
          b: 100,
        },
        RGB: {
          R: 255,
          G: 255,
          B: 255,
        },
        CMYK: {
          C: 0,
          M: 0,
          Y: 0,
          K: 0,
        },
        HEX: "FFFFFF",
      },
      colorMode: "HSB.H",
    };
  },
  methods: {
    // 绘制颜色选择框与选择条
    draw() {
      this.rectCtx.clearRect(0, 0, this.rectWidth, this.rectHeight);
      this.barCtx.clearRect(0, 0, 1, this.barHeight);
      this.rectCtx.globalCompositeOperation = "source-over";
      this.barCtx.globalCompositeOperation = "source-over";
      let grd;
      switch (this.colorMode) {
        case "HSB.H":
          grd = this.rectCtx.createLinearGradient(0, 0, this.rectWidth, 0);
          grd.addColorStop(0, "#fff");
          grd.addColorStop(1, `hsl(${this.colorValue.HSB.H}, 100%, 50%)`);
          this.rectCtx.fillStyle = grd;
          this.rectCtx.fillRect(0, 0, this.rectWidth, this.rectHeight);

          grd = this.rectCtx.createLinearGradient(0, this.rectHeight, 0, 0);
          grd.addColorStop(0, "#000");
          grd.addColorStop(1, "#0000");
          this.rectCtx.fillStyle = grd;
          this.rectCtx.fillRect(0, 0, this.rectWidth, this.rectHeight);

          grd = this.barCtx.createLinearGradient(0, this.barHeight, 0, 0);
          grd.addColorStop(0, "#f00");
          grd.addColorStop(1 / 6, "#ff0");
          grd.addColorStop(2 / 6, "#0f0");
          grd.addColorStop(3 / 6, "#0ff");
          grd.addColorStop(4 / 6, "#00f");
          grd.addColorStop(5 / 6, "#f0f");
          grd.addColorStop(1, "#f00");
          this.barCtx.fillStyle = grd;
          this.barCtx.fillRect(0, 0, 1, this.barHeight);
          break;
        case "HSB.S":
          grd = this.rectCtx.createLinearGradient(0, 0, this.rectWidth, 0);
          grd.addColorStop(0, "#f00");
          grd.addColorStop(1 / 6, "#ff0");
          grd.addColorStop(2 / 6, "#0f0");
          grd.addColorStop(3 / 6, "#0ff");
          grd.addColorStop(4 / 6, "#00f");
          grd.addColorStop(5 / 6, "#f0f");
          grd.addColorStop(1, "#f00");
          this.rectCtx.fillStyle = grd;
          this.rectCtx.fillRect(0, 0, this.rectWidth, this.rectHeight);

          grd = this.rectCtx.createLinearGradient(0, this.rectHeight, 0, 0);
          grd.addColorStop(0, "#000");
          grd.addColorStop(
            1,
            `rgba(255, 255, 255, ${1 - this.colorValue.HSB.S / 100})`
          );
          this.rectCtx.fillStyle = grd;
          this.rectCtx.fillRect(0, 0, this.rectWidth, this.rectHeight);

          grd = this.barCtx.createLinearGradient(0, this.barHeight, 0, 0);
          grd.addColorStop(
            0,
            `hsl(${this.colorValue.HSB.H}, 0%, ${this.colorValue.HSB.B}%)`
          );
          grd.addColorStop(
            1,
            `hsl(${this.colorValue.HSB.H}, 100%, ${this.colorValue.HSB.B / 2}%)`
          );
          this.barCtx.fillStyle = grd;
          this.barCtx.fillRect(0, 0, 1, this.barHeight);
          break;
        case "HSB.B":
          grd = this.rectCtx.createLinearGradient(0, 0, this.rectWidth, 0);
          grd.addColorStop(0, "#f00");
          grd.addColorStop(1 / 6, "#ff0");
          grd.addColorStop(2 / 6, "#0f0");
          grd.addColorStop(3 / 6, "#0ff");
          grd.addColorStop(4 / 6, "#00f");
          grd.addColorStop(5 / 6, "#f0f");
          grd.addColorStop(1, "#f00");
          this.rectCtx.fillStyle = grd;
          this.rectCtx.fillRect(0, 0, this.rectWidth, this.rectHeight);

          grd = this.rectCtx.createLinearGradient(0, this.rectHeight, 0, 0);
          grd.addColorStop(0, `hsl(0, 0%, ${this.colorValue.HSB.B}%)`);
          grd.addColorStop(
            1,
            `rgba(0, 0, 0, ${1 - this.colorValue.HSB.B / 100})`
          );
          this.rectCtx.fillStyle = grd;
          this.rectCtx.fillRect(0, 0, this.rectWidth, this.rectHeight);

          grd = this.barCtx.createLinearGradient(0, this.barHeight, 0, 0);
          grd.addColorStop(0, "#000");
          grd.addColorStop(
            1,
            `hsl(${this.colorValue.HSB.H}, 100%, ${
              100 - this.colorValue.HSB.S / 2
            }%)`
          );
          this.barCtx.fillStyle = grd;
          this.barCtx.fillRect(0, 0, 1, this.barHeight);
          break;
        // case "Lab.L":
        //   // for (let x = -128; x < 128; x++) {
        //   //   for (let y = -128; y < 128; y++) {
        //   //     let RGB = Lab2RGB({
        //   //       L: this.colorValue.Lab.L,
        //   //       a: x,
        //   //       b: y,
        //   //     });
        //   //     this.rectCtx.fillStyle = `rgb(${[RGB.R, RGB.G, RGB.B]})`;
        //   //     this.rectCtx.fillRect(x + 128, 127 - y, x + 129, 128 - y);
        //   //   }
        //   // }
        //   break;
        // case "Lab.a":
        //   break;
        // case "Lab.b":
        //   break;
        case "RGB.R":
          grd = this.rectCtx.createLinearGradient(0, 0, this.rectWidth, 0);
          grd.addColorStop(0, `rgb(${this.colorValue.RGB.R}, 0, 0)`);
          grd.addColorStop(1, `rgb(${this.colorValue.RGB.R}, 0, 255)`);
          this.rectCtx.fillStyle = grd;
          this.rectCtx.fillRect(0, 0, this.rectWidth, this.rectHeight);

          grd = this.rectCtx.createLinearGradient(0, this.rectHeight, 0, 0);
          grd.addColorStop(0, `rgb(0, 0, 0)`);
          grd.addColorStop(1, `rgb(0, 255, 0)`);
          this.rectCtx.fillStyle = grd;
          this.rectCtx.globalCompositeOperation = "lighter";
          this.rectCtx.fillRect(0, 0, this.rectWidth, this.rectHeight);

          grd = this.barCtx.createLinearGradient(0, this.barHeight, 0, 0);
          grd.addColorStop(
            0,
            `rgb(${[0, this.colorValue.RGB.G, this.colorValue.RGB.B]})`
          );
          grd.addColorStop(
            1,
            `rgb(${[255, this.colorValue.RGB.G, this.colorValue.RGB.B]})`
          );
          this.barCtx.fillStyle = grd;
          this.barCtx.fillRect(0, 0, 1, this.barHeight);
          break;
        case "RGB.G":
          grd = this.rectCtx.createLinearGradient(0, 0, this.rectWidth, 0);
          grd.addColorStop(0, `rgb(0, ${this.colorValue.RGB.G}, 0)`);
          grd.addColorStop(1, `rgb(0, ${this.colorValue.RGB.G}, 255)`);
          this.rectCtx.fillStyle = grd;
          this.rectCtx.fillRect(0, 0, this.rectWidth, this.rectHeight);

          grd = this.rectCtx.createLinearGradient(0, this.rectHeight, 0, 0);
          grd.addColorStop(0, `rgb(0, 0, 0)`);
          grd.addColorStop(1, `rgb(255, 0, 0)`);
          this.rectCtx.fillStyle = grd;
          this.rectCtx.globalCompositeOperation = "lighter";
          this.rectCtx.fillRect(0, 0, this.rectWidth, this.rectHeight);

          grd = this.barCtx.createLinearGradient(0, this.barHeight, 0, 0);
          grd.addColorStop(
            0,
            `rgb(${[this.colorValue.RGB.R, 0, this.colorValue.RGB.B]})`
          );
          grd.addColorStop(
            1,
            `rgb(${[this.colorValue.RGB.R, 255, this.colorValue.RGB.B]})`
          );
          this.barCtx.fillStyle = grd;
          this.barCtx.fillRect(0, 0, 1, this.barHeight);
          break;
        case "RGB.B":
          grd = this.rectCtx.createLinearGradient(0, 0, this.rectWidth, 0);
          grd.addColorStop(0, `rgb(0, 0, ${this.colorValue.RGB.B})`);
          grd.addColorStop(1, `rgb(255, 0, ${this.colorValue.RGB.B})`);
          this.rectCtx.fillStyle = grd;
          this.rectCtx.fillRect(0, 0, this.rectWidth, this.rectHeight);

          grd = this.rectCtx.createLinearGradient(0, this.rectHeight, 0, 0);
          grd.addColorStop(0, `rgb(0, 0, 0)`);
          grd.addColorStop(1, `rgb(0, 255, 0)`);
          this.rectCtx.fillStyle = grd;
          this.rectCtx.globalCompositeOperation = "lighter";
          this.rectCtx.fillRect(0, 0, this.rectWidth, this.rectHeight);

          grd = this.barCtx.createLinearGradient(0, this.barHeight, 0, 0);
          grd.addColorStop(
            0,
            `rgb(${[this.colorValue.RGB.R, this.colorValue.RGB.G, 0]})`
          );
          grd.addColorStop(
            1,
            `rgb(${[this.colorValue.RGB.R, this.colorValue.RGB.G, 255]})`
          );
          this.barCtx.fillStyle = grd;
          this.barCtx.fillRect(0, 0, 1, this.barHeight);
          break;
        default:
          break;
      }
    },
    // 颜色选择框鼠标按下
    rectMousedown(event) {
      this.holding = "rect";
      this.holdingMove(event.clientX, event.clientY);
    },
    // 颜色选择框触摸屏手指按下
    rectTouchstart(event) {
      this.holding = "rect";
      let touch = event.changedTouches[0];
      this.touchId = touch.identifier;
      this.holdingMove(touch.clientX, touch.clientY);
    },
    // 颜色选择条鼠标按下
    barMousedown(event) {
      this.holding = "bar";
      this.holdingMove(event.clientX, event.clientY);
    },
    // 颜色选择条触摸屏手指按下
    barTouchstart(event) {
      this.holding = "bar";
      let touch = event.changedTouches[0];
      this.touchId = touch.identifier;
      this.holdingMove(touch.clientX, touch.clientY);
    },
    // 鼠标移动
    mousemove(event) {
      if (this.holding) this.holdingMove(event.clientX, event.clientY);
    },
    // 触摸屏手指移动
    touchmove(event) {
      if (this.holding) {
        for (let item of event.touches) {
          if (item.identifier === this.touchId) {
            this.holdingMove(item.clientX, item.clientY);
            break;
          }
        }
      }
    },
    // 拖动
    holdingMove(x, y) {
      if (this.holdDebounce) return;
      this.holdDebounce = true;
      this.$nextFrame(() => (this.holdDebounce = false));
      if (this.holding) {
        switch (this.holding) {
          case "rect":
            {
              let rect = this.$refs.rect.getBoundingClientRect();
              this.selectValue.x = Math.min(
                Math.max((x - rect.left) / rect.width, 0),
                1
              );
              this.selectValue.y = Math.min(
                Math.max((rect.bottom - y) / rect.height, 0),
                1
              );
              this.rectToValue(this.selectValue.x, this.selectValue.y);
            }
            break;
          case "bar":
            {
              let bar = this.$refs.bar.getBoundingClientRect();
              this.selectValue.z = Math.min(
                Math.max((bar.bottom - y) / bar.height, 0),
                1
              );
              this.barToValue(this.selectValue.z);
            }
            break;
          default:
            break;
        }
      }
    },
    // 触摸屏手指抬起
    touchend(event) {
      if (event.changedTouches[0].identifier === this.touchId) {
        this.touchId = null;
        this.holdingEnd();
      }
    },
    // 鼠标、手指拖动结束
    holdingEnd() {
      this.holding = "";
    },
    // 颜色选择框转换成数值
    rectToValue(x, y) {
      switch (this.colorMode) {
        case "HSB.H":
          this.colorValue.HSB.S = Math.round(x * 100);
          this.colorValue.HSB.B = Math.round(y * 100);
          this.valueChangeHSB();
          break;
        case "HSB.S":
          this.colorValue.HSB.H = Math.round(x * 360);
          this.colorValue.HSB.B = Math.round(y * 100);
          this.valueChangeHSB();
          break;
        case "HSB.B":
          this.colorValue.HSB.H = Math.round(x * 360);
          this.colorValue.HSB.S = Math.round(y * 100);
          this.valueChangeHSB();
          break;
        // case "Lab.L":
        //   this.colorValue.Lab.a = Math.round(x * 255 - 128);
        //   this.colorValue.Lab.b = Math.round(y * 255 - 128);
        //   this.valueChangeLab();
        //   break;
        // case "Lab.a":
        //   this.colorValue.Lab.b = Math.round(x * 255 - 128);
        //   this.colorValue.Lab.L = Math.round(y * 100);
        //   this.valueChangeLab();
        //   break;
        // case "Lab.b":
        //   this.colorValue.Lab.a = Math.round(x * 255 - 128);
        //   this.colorValue.Lab.L = Math.round(y * 100);
        //   this.valueChangeLab();
        //   break;
        case "RGB.R":
          this.colorValue.RGB.B = Math.round(x * 255);
          this.colorValue.RGB.G = Math.round(y * 255);
          this.valueChangeRGB();
          break;
        case "RGB.G":
          this.colorValue.RGB.B = Math.round(x * 255);
          this.colorValue.RGB.R = Math.round(y * 255);
          this.valueChangeRGB();
          break;
        case "RGB.B":
          this.colorValue.RGB.R = Math.round(x * 255);
          this.colorValue.RGB.G = Math.round(y * 255);
          this.valueChangeRGB();
          break;
        default:
          break;
      }
      this.$emit("update:value", this.colorValue.RGB);
      this.$emit("change", this.colorValue.RGB);
    },
    // 颜色选择条转换成数值
    barToValue(z) {
      switch (this.colorMode) {
        case "HSB.H":
          this.colorValue.HSB.H = Math.round(z * 360);
          this.valueChangeHSB();
          break;
        case "HSB.S":
          this.colorValue.HSB.S = Math.round(z * 100);
          this.valueChangeHSB();
          break;
        case "HSB.B":
          this.colorValue.HSB.B = Math.round(z * 100);
          this.valueChangeHSB();
          break;
        // case "Lab.L":
        //   this.colorValue.Lab.L = Math.round(z * 100);
        //   this.valueChangeLab();
        //   break;
        // case "Lab.a":
        //   this.colorValue.Lab.a = Math.round(z * 255 - 128);
        //   this.valueChangeLab();
        //   break;
        // case "Lab.b":
        //   this.colorValue.Lab.b = Math.round(z * 255 - 128);
        //   this.valueChangeLab();
        //   break;
        case "RGB.R":
          this.colorValue.RGB.R = Math.round(z * 255);
          this.valueChangeRGB();
          break;
        case "RGB.G":
          this.colorValue.RGB.G = Math.round(z * 255);
          this.valueChangeRGB();
          break;
        case "RGB.B":
          this.colorValue.RGB.B = Math.round(z * 255);
          this.valueChangeRGB();
          break;
        default:
          break;
      }
      this.$emit("update:value", this.colorValue.RGB);
      this.$emit("change", this.colorValue.RGB);
    },
    // 颜色模式改变
    colorModeChange(mode) {
      this.colorMode = mode;
      this.valueToRectAndBar();
      switch (this.colorMode) {
        case "HSB.H":
          this.rectWidth = 100;
          this.rectHeight = 100;
          this.barHeight = 360;
          break;
        case "HSB.S":
          this.rectWidth = 360;
          this.rectHeight = 100;
          this.barHeight = 100;
          break;
        case "HSB.B":
          this.rectWidth = 360;
          this.rectHeight = 100;
          this.barHeight = 100;
          break;
        // case "Lab.L":
        //   this.rectWidth = 255;
        //   this.rectHeight = 255;
        //   this.barHeight = 100;
        //   break;
        // case "Lab.a":
        //   this.rectWidth = 255;
        //   this.rectHeight = 100;
        //   this.barHeight = 255;
        //   break;
        // case "Lab.b":
        //   this.rectWidth = 255;
        //   this.rectHeight = 100;
        //   this.barHeight = 255;
        //   break;
        case "RGB.R":
          this.rectWidth = 255;
          this.rectHeight = 255;
          this.barHeight = 255;
          break;
        case "RGB.G":
          this.rectWidth = 255;
          this.rectHeight = 255;
          this.barHeight = 255;
          break;
        case "RGB.B":
          this.rectWidth = 255;
          this.rectHeight = 255;
          this.barHeight = 255;
          break;
        default:
          break;
      }
    },
    // HSB颜色改变
    valueChangeHSB() {
      // 转换成RGB
      let resRGB = HSB2RGB(this.colorValue.HSB);
      this.colorValue.RGB.R = Math.round(resRGB.R);
      this.colorValue.RGB.G = Math.round(resRGB.G);
      this.colorValue.RGB.B = Math.round(resRGB.B);
      // RGB转换成HEX(16进制)
      let resHEX = RGB2HEX(resRGB);
      this.colorValue.HEX = resHEX;
      if (this.simple) return;
      // RGB转换成Lab
      let resLab = RGB2Lab(resRGB);
      this.colorValue.Lab.L = Math.round(resLab.L);
      this.colorValue.Lab.a = Math.round(resLab.a);
      this.colorValue.Lab.b = Math.round(resLab.b);
      // RGB转换成CMYK
      let resCMYK = RGB2CMYK(resRGB);
      this.colorValue.CMYK.C = Math.round(resCMYK.C);
      this.colorValue.CMYK.M = Math.round(resCMYK.M);
      this.colorValue.CMYK.Y = Math.round(resCMYK.Y);
      this.colorValue.CMYK.K = Math.round(resCMYK.K);
    },
    // Lab颜色改变
    valueChangeLab() {
      // 转换成RGB
      let resRGB = Lab2RGB(this.colorValue.Lab);
      this.colorValue.RGB.R = Math.round(resRGB.R);
      this.colorValue.RGB.G = Math.round(resRGB.G);
      this.colorValue.RGB.B = Math.round(resRGB.B);
      // RGB转换成HSB
      let resHSB = RGB2HSB(resRGB);
      if (resHSB.H !== undefined) this.colorValue.HSB.H = Math.round(resHSB.H);
      this.colorValue.HSB.S = Math.round(resHSB.S);
      this.colorValue.HSB.B = Math.round(resHSB.B);
      // RGB转换成CMYK
      let resCMYK = RGB2CMYK(resRGB);
      this.colorValue.CMYK.C = Math.round(resCMYK.C);
      this.colorValue.CMYK.M = Math.round(resCMYK.M);
      this.colorValue.CMYK.Y = Math.round(resCMYK.Y);
      this.colorValue.CMYK.K = Math.round(resCMYK.K);
      // RGB转换成HEX(16进制)
      let resHEX = RGB2HEX(resRGB);
      this.colorValue.HEX = resHEX;
    },
    // RGB颜色改变
    valueChangeRGB() {
      // 转换成HSB
      let resHSB = RGB2HSB(this.colorValue.RGB);
      if (resHSB.H !== undefined) this.colorValue.HSB.H = Math.round(resHSB.H);
      this.colorValue.HSB.S = Math.round(resHSB.S);
      this.colorValue.HSB.B = Math.round(resHSB.B);
      // 转换成HEX(16进制)
      let resHEX = RGB2HEX(this.colorValue.RGB);
      this.colorValue.HEX = resHEX;
      if (this.simple) return;
      // 转换成Lab
      let resLab = RGB2Lab(this.colorValue.RGB);
      this.colorValue.Lab.L = Math.round(resLab.L);
      this.colorValue.Lab.a = Math.round(resLab.a);
      this.colorValue.Lab.b = Math.round(resLab.b);
      // 转换成CMYK
      let resCMYK = RGB2CMYK(this.colorValue.RGB);
      this.colorValue.CMYK.C = Math.round(resCMYK.C);
      this.colorValue.CMYK.M = Math.round(resCMYK.M);
      this.colorValue.CMYK.Y = Math.round(resCMYK.Y);
      this.colorValue.CMYK.K = Math.round(resCMYK.K);
    },
    // CMYK颜色改变
    valueChangeCMYK() {
      // 转换成RGB
      let resRGB = CMYK2RGB(this.colorValue.CMYK);
      this.colorValue.RGB.R = Math.round(resRGB.R);
      this.colorValue.RGB.G = Math.round(resRGB.G);
      this.colorValue.RGB.B = Math.round(resRGB.B);
      // RGB转换成HSB
      let resHSB = RGB2HSB(this.colorValue.RGB);
      if (resHSB.H !== undefined) this.colorValue.HSB.H = Math.round(resHSB.H);
      this.colorValue.HSB.S = Math.round(resHSB.S);
      this.colorValue.HSB.B = Math.round(resHSB.B);
      // RGB转换成Lab
      let resLab = RGB2Lab(this.colorValue.RGB);
      this.colorValue.Lab.L = Math.round(resLab.L);
      this.colorValue.Lab.a = Math.round(resLab.a);
      this.colorValue.Lab.b = Math.round(resLab.b);
      // RGB转换成HEX(16进制)
      let resHEX = RGB2HEX(this.colorValue.RGB);
      this.colorValue.HEX = resHEX;
    },
    // HEX(16进制)颜色改变
    valueChangeHEX() {
      // 转换成RGB
      let resRGB = HEX2RGB(this.colorValue.HEX);
      this.colorValue.RGB.R = Math.round(resRGB.R);
      this.colorValue.RGB.G = Math.round(resRGB.G);
      this.colorValue.RGB.B = Math.round(resRGB.B);
      // 转换成HSB
      let resHSB = RGB2HSB(this.colorValue.RGB);
      if (resHSB.H !== undefined) this.colorValue.HSB.H = Math.round(resHSB.H);
      this.colorValue.HSB.S = Math.round(resHSB.S);
      this.colorValue.HSB.B = Math.round(resHSB.B);
      if (this.simple) return;
      // 转换成Lab
      let resLab = RGB2Lab(this.colorValue.RGB);
      this.colorValue.Lab.L = Math.round(resLab.L);
      this.colorValue.Lab.a = Math.round(resLab.a);
      this.colorValue.Lab.b = Math.round(resLab.b);
      // 转换成CMYK
      let resCMYK = RGB2CMYK(this.colorValue.RGB);
      this.colorValue.CMYK.C = Math.round(resCMYK.C);
      this.colorValue.CMYK.M = Math.round(resCMYK.M);
      this.colorValue.CMYK.Y = Math.round(resCMYK.Y);
      this.colorValue.CMYK.K = Math.round(resCMYK.K);
    },
    // 输入框改变颜色值
    inputValueChange(callback) {
      this.valueToRectAndBar(callback);
      this.$emit("change", this.colorValue.RGB);
    },
    // 数值转换成颜色选择框和颜色选择条
    valueToRectAndBar(callback) {
      if (typeof callback === "function") {
        callback();
      }
      switch (this.colorMode) {
        case "HSB.H":
          this.selectValue.x = this.colorValue.HSB.S / 100;
          this.selectValue.y = this.colorValue.HSB.B / 100;
          this.selectValue.z = this.colorValue.HSB.H / 360;
          break;
        case "HSB.S":
          this.selectValue.x = this.colorValue.HSB.H / 360;
          this.selectValue.y = this.colorValue.HSB.B / 100;
          this.selectValue.z = this.colorValue.HSB.S / 100;
          break;
        case "HSB.B":
          this.selectValue.x = this.colorValue.HSB.H / 360;
          this.selectValue.y = this.colorValue.HSB.S / 100;
          this.selectValue.z = this.colorValue.HSB.B / 100;
          break;
        // case "Lab.L":
        //   this.selectValue.x = (this.colorValue.Lab.a + 128) / 255;
        //   this.selectValue.y = (this.colorValue.Lab.b + 128) / 255;
        //   this.selectValue.z = this.colorValue.Lab.L / 100;
        //   break;
        // case "Lab.a":
        //   this.selectValue.x = (this.colorValue.Lab.b + 128) / 255;
        //   this.selectValue.y = this.colorValue.Lab.L / 100;
        //   this.selectValue.z = (this.colorValue.Lab.a + 128) / 255;
        //   break;
        // case "Lab.b":
        //   this.selectValue.x = (this.colorValue.Lab.a + 128) / 255;
        //   this.selectValue.y = this.colorValue.Lab.L / 100;
        //   this.selectValue.z = (this.colorValue.Lab.b + 128) / 255;
        //   break;
        case "RGB.R":
          this.selectValue.x = this.colorValue.RGB.B / 255;
          this.selectValue.y = this.colorValue.RGB.G / 255;
          this.selectValue.z = this.colorValue.RGB.R / 255;
          break;
        case "RGB.G":
          this.selectValue.x = this.colorValue.RGB.B / 255;
          this.selectValue.y = this.colorValue.RGB.R / 255;
          this.selectValue.z = this.colorValue.RGB.G / 255;
          break;
        case "RGB.B":
          this.selectValue.x = this.colorValue.RGB.R / 255;
          this.selectValue.y = this.colorValue.RGB.G / 255;
          this.selectValue.z = this.colorValue.RGB.B / 255;
          break;
        default:
          break;
      }
    },
  },
  watch: {
    value: {
      handler(newValue) {
        this.colorValue.RGB.R = newValue?.R || 0;
        this.colorValue.RGB.G = newValue?.G || 0;
        this.colorValue.RGB.B = newValue?.B || 0;
        if (!this.holding) this.valueToRectAndBar(this.valueChangeRGB());
      },
      immediate: true,
    },
    holding(newValue) {
      if (newValue) {
        Object.assign(this.$mask.style, {
          display: "block",
          cursor: "grabbing",
        });
        window.addEventListener("mousemove", this.mousemove);
        window.addEventListener("touchmove", this.touchmove);
        window.addEventListener("mouseup", this.holdingEnd);
        window.addEventListener("mouseleave", this.holdingEnd);
        window.addEventListener("touchend", this.touchend);
      } else {
        this.$mask.style.display = "none";
        window.removeEventListener("mousemove", this.mousemove);
        window.removeEventListener("touchmove", this.touchmove);
        window.removeEventListener("mouseup", this.holdingEnd);
        window.removeEventListener("mouseleave", this.holdingEnd);
        window.removeEventListener("touchend", this.touchend);
      }
    },
  },
  mounted() {
    this.rectCanvas = this.$refs.rect;
    this.barCanvas = this.$refs.bar;
    if (this.rectCanvas.getContext) {
      this.rectCtx = this.rectCanvas.getContext("2d");
      this.barCtx = this.barCanvas.getContext("2d");
      this.$animationFrameFuncAdd(this.draw);
    }
  },
  beforeUnmount() {
    this.$animationFrameFuncRemove(this.draw);
  },
};
</script>

<style lang="scss" scoped>
.pui-color-selector {
  width: 100%;
  @media (min-width: 768px) {
    display: flex;
  }
  @media (max-width: 767px) {
    margin: 0 auto;
  }
  &.pui-color-selector-simple {
    .pui-color-selector-canvases {
      .pui-color-selector-rect {
        @media (min-width: 768px) {
          @media (min-width: 992px) {
            width: 192px;
            height: 192px;
          }
          @media (max-width: 991px) {
            width: 164px;
            height: 164px;
          }
        }
      }
      .pui-color-selector-bar {
        @media (min-width: 768px) {
          @media (min-width: 992px) {
            height: 192px;
          }
          @media (max-width: 991px) {
            height: 164px;
          }
        }
      }
    }
    .pui-color-selector-form {
      @media (min-width: 768px) {
        @media (min-width: 992px) {
          height: 192px;
        }
        @media (max-width: 991px) {
          height: 164px;
        }
      }
      .pui-color-selector-form-group {
        &.pui-color-selector-form-group-HEX {
          grid-column-start: span 2;
        }
        .pui-color-selector-form-row {
          @media (min-width: 992px) {
            margin: 8px 16px;
          }
          @media (min-width: 768px) and (max-width: 991px) {
            margin: 4px 8px;
          }
          @media (max-width: 767px) {
            margin: 0.8vw 1.6vw;
          }
        }
      }
    }
  }
  &:not(.pui-color-selector-simple) {
    .pui-color-selector-canvases {
      .pui-color-selector-rect {
        @media (min-width: 768px) {
          @media (min-width: 992px) {
            width: 292px;
            height: 292px;
          }
          @media (max-width: 991px) {
            width: 236px;
            height: 236px;
          }
        }
      }
      .pui-color-selector-bar {
        @media (min-width: 768px) {
          @media (min-width: 992px) {
            height: 292px;
          }
          @media (max-width: 991px) {
            height: 236px;
          }
        }
      }
    }
    .pui-color-selector-form {
      @media (min-width: 768px) {
        @media (min-width: 992px) {
          height: 292px;
        }
        @media (max-width: 991px) {
          height: 236px;
        }
      }
      .pui-color-selector-form-group {
        &.pui-color-selector-form-group-Lab,
        &.pui-color-selector-form-group-RGB,
        &.pui-color-selector-form-group-HEX {
          @media (min-width: 768px) {
            padding-right: 24px;
          }
          @media (max-width: 767px) {
            padding-right: 4.8vw;
          }
        }
        &.pui-color-selector-form-group-CMYK {
          grid-row-start: span 2;
        }
        .pui-color-selector-form-row {
          @media (min-width: 768px) {
            @media (min-width: 992px) {
              margin: 4px 16px;
            }
            @media (max-width: 991px) {
              margin: 2px 8px;
            }
          }
          @media (max-width: 767px) {
            margin: 0.4vw 1.6vw;
          }
          .pui-input {
            @media (min-width: 768px) {
              @media (min-width: 992px) {
                height: 32px;
              }
              @media (max-width: 991px) {
                height: 28px;
              }
            }
            @media (max-width: 767px) {
              height: 6.4vw;
            }
          }
        }
      }
    }
  }
  .pui-color-selector-canvases {
    display: flex;
    justify-content: center;
    .pui-color-selector-rect {
      position: relative;
      overflow: hidden;
      @media (max-width: 767px) {
        width: 59vw;
        height: 59vw;
      }
      canvas {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
      .selector-circle {
        border-radius: 50%;
        border-style: solid;
        position: absolute;
        transform: translate3d(-50%, 50%, 0);
        transition-property: none;
        cursor: grab;
        @media (min-width: 768px) {
          width: 16px;
          height: 16px;
          border-width: 2px;
        }
        @media (max-width: 767px) {
          width: 4vw;
          height: 4vw;
          border-width: 0.5vw;
        }
      }
    }
    .pui-color-selector-bar {
      position: relative;
      @media (min-width: 768px) {
        width: 16px;
        margin-left: 16px;
      }
      @media (max-width: 767px) {
        width: 4vw;
        height: 59vw;
        margin-left: 4vw;
      }
      canvas {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
      .selector-arrow {
        border-color: transparent rgba(var(--color0-light4), 1);
        border-style: solid;
        position: absolute;
        left: 50%;
        transform: translate3d(-50%, 50%, 0);
        transition-property: none;
        cursor: grab;
        @media (min-width: 768px) {
          width: 32px;
          height: 6px;
          border-width: 3px 6px;
        }
        @media (max-width: 767px) {
          width: 8vw;
          height: 1.5vw;
          border-width: 0.75vw 1.5vw;
        }
      }
    }
  }
  .pui-color-selector-form {
    flex: 1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-content: start;
    overflow: hidden;
    @media (min-width: 768px) {
      margin-left: 16px;
      @media (min-width: 992px) {
        gap: 8px;
      }
      @media (max-width: 991px) {
        gap: 4px;
      }
    }
    @media (max-width: 767px) {
      margin-top: 4vw;
      gap: 0.8vw;
    }
    .pui-color-selector-form-group {
      background-color: rgba(var(--color-bg), 0.5);
      @media (min-width: 768px) {
        padding: 2px 0;
        border-radius: 5px;
      }
      @media (max-width: 767px) {
        padding: 0.4vw 0;
        border-radius: 1vw;
      }
      .pui-color-selector-form-row {
        display: flex;
        align-items: center;
        .pui-radio {
          @media (min-width: 768px) {
            margin-right: 4px;
          }
          @media (max-width: 767px) {
            margin-right: 0.8vw;
          }
        }
        .pui-input {
          flex: 1;
          :deep(.pui-input-label),
          :deep(.pui-input-after) {
            text-align: center;
            @media (min-width: 768px) {
              width: 24px;
            }
            @media (max-width: 767px) {
              width: 4.8vw;
            }
          }
        }
      }
    }
  }
}
</style>