<template>
  <div class="pui-input">
    <div v-if="label" class="pui-input-label" @click="$refs.input.focus">
      {{ label }}
    </div>
    <PuiSlider
      v-if="
        (inputAttr.type + '').toLowerCase() === 'number' && inputAttr.slider
      "
      class="pui-input-slider"
      :value="(cValue - inputAttr.min) * (inputAttr.max - inputAttr.min)"
      :step="(inputAttr.max - inputAttr.min) / inputAttr.step"
      @change="changeSlider"
      @changeEnd="changeSlider"
    />
    <input
      ref="input"
      class="pui-input-input"
      v-model="cValue"
      v-bind="inputAttr"
      @input.stop="change"
      @change.stop="change"
      @blur.stop="changeEnd"
    />
    <div v-if="after" class="pui-input-after">
      {{ after }}
    </div>
  </div>
</template>

<script>
import PuiSlider from "@/components/pizza-ui/PuiSlider";
export default {
  components: {
    PuiSlider,
  },
  props: {
    value: [String, Number],
    label: String,
    inputAttr: Object,
    filter: Function,
    after: String,
  },
  data() {
    return {
      cValue: 0,
      inputting: false,
    };
  },
  methods: {
    change() {
      let value = this.$refs.input.value;
      if ((this.inputAttr.type + "").toLowerCase() === "number") {
        if (Number(value) < this.inputAttr.min) value = this.inputAttr.min;
        if (Number(value) > this.inputAttr.max) value = this.inputAttr.max;
        if (this.inputAttr.precision === 0)
          value = (value + "").replace(/[.]/g, "");
        else if (
          this.inputAttr.precision > 0 &&
          (value + ".").split(".")[1].length > this.inputAttr.precision
        )
          value = this.cValue;
      } else {
        if ((value + "").length > this.inputAttr.maxlength) value = this.cValue;
      }
      if (this.filter) value = this.filter(value);
      this.cValue = value;
      this.$emit("update:value", value);
      this.$emit("change", value);
    },
    changeEnd() {
      if ((this.inputAttr.type + "").toLowerCase() === "number") {
        this.cValue = Number(
          Number(this.cValue).toFixed(this.inputAttr.precision)
        );
      }
      this.$refs.input.value = this.cValue;
      this.$emit("changeEnd", this.cValue);
    },
    changeSlider(value) {
      this.cValue = value * (this.inputAttr.max - this.inputAttr.min) + this.inputAttr.min;
      this.changeEnd();
    },
  },
  watch: {
    value: {
      handler(newValue) {
        if ((this.inputAttr.type + "").toLowerCase() === "number") {
          if (Number(newValue) < this.inputAttr.min)
            newValue = this.inputAttr.min;
          if (Number(newValue) > this.inputAttr.max)
            newValue = this.inputAttr.max;
          if (this.inputAttr.precision === 0)
            newValue = (newValue + "").split(".")[0];
          else if (
            this.inputAttr.precision > 0 &&
            (newValue + ".").split(".")[1].length > this.inputAttr.precision
          )
            newValue = Number(
              (newValue + "")
                .split(".")
                .map((item, index) =>
                  index ? item.slice(0, this.inputAttr.precision) : item
                )
                .join(".")
            );
        } else {
          if ((newValue + "").length > this.inputAttr.maxlength)
            newValue = this.cValue;
        }
        if (this.filter) newValue = this.filter(newValue);
        this.cValue = newValue;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.pui-input {
  display: inline-flex;
  align-items: center;
  @media (min-width: 768px) {
    height: 32px;
  }
  @media (max-width: 767px) {
    height: 6.4vw;
  }
  .pui-input-label {
    text-align: right;
    @media (min-width: 768px) {
      padding-right: 8px;
    }
    @media (max-width: 767px) {
      padding-right: 1.6vw;
    }
  }
  .pui-input-slider {
    width: 100%;
    flex: 2;
    @media (min-width: 768px) {
      margin-right: 8px;
    }
    @media (max-width: 767px) {
      margin-right: 1.6vw;
    }
  }
  .pui-input-input {
    width: 100%;
    flex: 1;
    height: 100%;
    background-color: rgba(var(--color-bg-dark4), 0.5);
    border-color: rgba(var(--color0-dark2), 0.5);
    border-style: solid;
    color: rgba(var(--color0-light4), 1);
    @media (min-width: 768px) {
      padding: 4px;
      border-width: 2px;
      border-radius: 5px;
    }
    @media (max-width: 767px) {
      padding: 0.8vw;
      border-width: 0.4vw;
      border-radius: 1vw;
    }
    // Chrome去按钮
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    // FireFox去按钮
    &[type="number"] {
      -moz-appearance: textfield;
    }
  }
  .pui-input-after {
    @media (min-width: 768px) {
      padding-left: 8px;
    }
    @media (max-width: 767px) {
      padding-left: 1.6vw;
    }
  }
}
</style>