import {
  musicList
} from "@/assets/js/musicList.js";

export default {
  namespaced: true,
  state: {
    audio: new Audio(),
    paused: true,
    volume: 1,
    muted: false,
    fadeInOutTimer: null,
    fadeInOutVolume: 1,
    musicIndex: 0,
    playMode: "order",
    playModeOptions: {
      order: {
        name: "顺序播放"
      },
      list: {
        name: "列表循环"
      },
      single: {
        name: "单曲播放"
      },
      loop: {
        name: "单曲循环"
      },
      shuffle: {
        name: "随机播放"
      },
    },
    shuffleList: [], // 随机播放的顺序
    analyser: null,
  },
  mutations: {
    // 初始化
    init(state) {
      state.audio.crossOrigin = "anonymous";
      // 获取本地播放设置
      let musicIndex = Math.round(localStorage.getItem("musicIndex"));
      if (musicList[musicIndex]) {
        state.musicIndex = musicIndex;
        state.audio.src = musicList[musicIndex].src;
      } else {
        state.musicIndex = -1;
        state.audio.src = "";
      }
      state.audio.currentTime = Number(localStorage.getItem("musicCurrentTime") || 0) || 0;
      // state.audio.currentTime = 40 * 60 / 32;
      state.volume = Math.min(Math.max(Number(localStorage.getItem("musicVolume") || 1) || 1, 0), 1);
      state.audio.muted = !!localStorage.getItem("musicMuted");
      let mode = localStorage.getItem("musicPlayMode");
      state.playMode = ["order", "list", "single", "loop", "shuffle"].indexOf(mode) > -1 ? mode : "order";
    },
    // 淡入淡出计时器
    createSource(state) {
      // 创建音频源数据
      let audioContext = new AudioContext();
      let source = audioContext.createMediaElementSource(state.audio);
      state.analyser = audioContext.createAnalyser();
      source.connect(state.analyser);
      state.analyser.connect(audioContext.destination);
      state.analyser.fftSize = 8192;
      state.analyser.smoothingTimeConstant = 0;
      state.analyser.maxDecibels = -20;
      state.analyser.minDecibels = -70;
    },
    // 淡入淡出计时器
    fadeInOutTimer(state, timer) {
      state.fadeInOutTimer = timer % 1 === 0 ? timer : null;
    },
    // 淡入淡出设置
    fadeInOut(state) {
      if (state.paused) {
        if (state.fadeInOutVolume > 0) {
          state.fadeInOutVolume = Math.max(state.fadeInOutVolume - 0.05, 0);
          state.audio.volume = Math.max(state.volume * state.fadeInOutVolume, 0);
        } else if (!state.audio.paused) state.audio.pause();
      } else {
        if (state.fadeInOutVolume < 1) {
          state.fadeInOutVolume = Math.min(state.fadeInOutVolume + 0.05, 1);
          state.audio.volume = Math.min(state.volume * state.fadeInOutVolume, 1);
        }
        if (state.audio.paused) state.audio.play();
      }
    },
    // 修改播放状态
    paused(state, paused) {
      state.paused = !!paused;
      if (!state.paused) state.audio.play();
    },
    // 修改音量
    volume(state, volume) {
      if (!(volume >= 0 && volume <= 1)) return;
      state.volume = volume;
      if (state.fadeInOutVolume === 1) state.audio.volume = volume;
      localStorage.setItem("musicVolume", volume);
    },
    // 修改播放状态
    muted(state, muted) {
      state.muted = !!muted;
      state.audio.muted = !!muted;
      localStorage.setItem("musicMuted", Number(!!muted));
    },
    // 修改播放模式
    playMode(state, mode) {
      if (["order", "list", "single", "loop", "shuffle"].indexOf(mode) < 0) return;
      state.playMode = mode;
      localStorage.setItem("musicPlayMode", mode);
    },
    // 切换歌曲
    switch (state, index) {
      if (!(index >= 0 && index < musicList.length)) return;
      state.musicIndex = index;
      state.audio.src = musicList[state.musicIndex].src;
      localStorage.setItem("musicIndex", state.musicIndex);
    },
    // 生成随机播放的顺序
    shuffle(state) {
      state.shuffleList = [];
      for (let i = 0; i < musicList.length; i++) {
        state.shuffleList.splice(Math.floor(Math.random() * (state.shuffleList.length + 1)), 0, i);
      }
    },
    // 播放结束（顺序播放）
    end_order(state) {
      if (++state.musicIndex >= musicList.length) {
        state.musicIndex = -1;
        state.audio.src = "";
        state.audio.currentTime = 0;
        state.paused = true;
        localStorage.setItem("musicIndex", -1);
        return;
      }
      state.audio.src = musicList[state.musicIndex].src;
      state.audio.play();
      localStorage.setItem("musicIndex", state.musicIndex);
    },
    // 播放结束（列表循环）
    end_list(state) {
      if (++state.musicIndex >= musicList.length) state.musicIndex = 0;
      state.audio.src = musicList[state.musicIndex].src;
      state.audio.play();
      localStorage.setItem("musicIndex", state.musicIndex);
    },
    // 播放结束（单曲播放）
    end_single(state) {
      state.musicIndex = -1;
      state.audio.src = "";
      state.audio.currentTime = 0;
      state.paused = true;
      localStorage.setItem("musicIndex", -1);
    },
    // 播放结束（单曲循环）
    end_loop(state) {
      state.audio.play();
    },
    // 播放结束（随机播放）
    end_shuffle(state) {
      state.musicIndex = state.shuffleList[(state.shuffleList.indexOf(state.musicIndex) + 1) % state.shuffleList.length];
      state.audio.src = musicList[state.musicIndex].src;
      state.audio.play();
      localStorage.setItem("musicIndex", state.musicIndex);
    },
  },
  actions: {
    // 初始化
    create(context) {
      context.commit("init");
        // 如果是随机播放则生成随机播放的顺序
      if (context.state.playMode === "shuffle") context.commit("shuffle");
      // 设置淡入淡出定时器
      let fadeInOutTimer = setInterval(() => {
        context.commit("fadeInOut");
      }, 50);
      context.commit("fadeInOutTimer", fadeInOutTimer);
      // 正常关闭页面之前保存播放歌曲索引与播放进度
      window.addEventListener("beforeunload", () => {
        localStorage.setItem("musicIndex", context.state.musicIndex);
        localStorage.setItem("musicCurrentTime", context.state.audio.currentTime);
      })
      // 播放结束
      context.state.audio.addEventListener("ended", () => context.commit("end_" + context.state.playMode));
      let createSource = () => {
        context.commit("createSource");
        window.removeEventListener("click", createSource);
      }
      window.addEventListener("click", createSource);
    },
    // 播放
    play(context) {
      if (context.state.musicIndex === -1) context.commit("switch", 0);
      context.commit("paused", false);
    },
    // 暂停
    pause(context) {
      context.commit("paused", true);
    },
    // 播放/暂停
    togglePaused(context) {
      if (context.state.paused && context.state.musicIndex === -1) context.commit("switch", 0);
      context.commit("paused", !context.state.paused);
    },
    // 改变音量
    changeVolume(context, volume) {
      if (!(volume >= 0 && volume <= 1)) return;
      context.commit("volume", volume);
    },
    // 音量加
    volumeUp(context) {
      let volume = Math.min(context.state.volume + 0.1, 1);
      context.commit("volume", volume);
    },
    // 音量减
    volumeDown(context) {
      let volume = Math.max(context.state.volume - 0.1, 0);
      context.commit("volume", volume);
    },
    // 切换静音
    toggleMuted(context) {
      context.commit("muted", !context.state.muted);
    },
    // 上一首
    prev(context) {
      let index = context.state.playMode === "shuffle" ? context.state.shuffleList[(context.state.shuffleList.indexOf(context.state.musicIndex) - 1 + context.state.shuffleList.length) % context.state.shuffleList.length] : (context.state.musicIndex - 1 + musicList.length) % musicList.length;
      context.commit("switch", index);
      context.dispatch("play");
    },
    // 下一首
    next(context) {
      let index = context.state.playMode === "shuffle" ? context.state.shuffleList[(context.state.shuffleList.indexOf(context.state.musicIndex) + 1) % context.state.shuffleList.length] : (context.state.musicIndex + 1) % musicList.length;
      context.commit("switch", index);
      context.dispatch("play");
    },
    // 切换歌曲
    switch (context, index) {
      if (!(index >= 0 && index < musicList.length)) return;
      if (index === context.state.musicIndex) {
        if (context.state.paused) context.dispatch("play");
        else context.dispatch("pause");
        return;
      }
      context.commit("switch", index);
      context.dispatch("play");
    },
    // 改变播放模式
    changePlayMode(context, mode) {
      if (["order", "list", "single", "loop", "shuffle"].indexOf(mode) < 0) return;
      context.commit("playMode", mode);
      if (mode === "shuffle") context.commit("shuffle");
    },
  },
  modules: {},
};