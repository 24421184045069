// 颜色模式转换

// HSB转RGB
export const HSB2RGB = data => {
  // 获取HSB
  let HSB = {};
  for (let key of ["H", "S", "B"]) {
    HSB[key] = Number(data[key]);
    if (isNaN(HSB[key])) {
      HSB[key] = 0;
    } else {
      if (key === "H") {
        HSB[key] %= 360;
      } else {
        HSB[key] = Math.min(Math.max(HSB[key] / 100, 0), 1);
      }
    }
  }
  // 计算RGB
  let RGB = {};
  if (HSB.S === 0) {
    RGB.R = HSB.B;
    RGB.G = HSB.B;
    RGB.B = HSB.B;
  } else {
    // 颜色轮盘由6个扇区组成。找出在哪个扇区。
    let sectorPos = HSB.H / 60;
    let sectorNumber = Math.floor(sectorPos);
    // 获取扇区的小数部分
    let fractionalSector = sectorPos - sectorNumber;
    // 计算颜色的三个轴的值。
    let P = HSB.B * (1 - HSB.S);
    let Q = HSB.B * (1 - HSB.S * fractionalSector);
    let T = HSB.B * (1 - HSB.S * (1 - fractionalSector));
    // 根据角度所在的扇区将颜色数值指定给R、G和B。
    switch (sectorNumber) {
      case 0:
        RGB.R = HSB.B;
        RGB.G = T;
        RGB.B = P;
        break;
      case 1:
        RGB.R = Q;
        RGB.G = HSB.B;
        RGB.B = P;
        break;
      case 2:
        RGB.R = P;
        RGB.G = HSB.B;
        RGB.B = T;
        break;
      case 3:
        RGB.R = P;
        RGB.G = Q;
        RGB.B = HSB.B;
        break;
      case 4:
        RGB.R = T;
        RGB.G = P;
        RGB.B = HSB.B;
        break;
      case 5:
        RGB.R = HSB.B;
        RGB.G = P;
        RGB.B = Q;
        break;
    }
  }
  RGB.R *= 255;
  RGB.G *= 255;
  RGB.B *= 255;
  return RGB;
};

// RGB转HSB
export const RGB2HSB = data => {
  // 获取RGB
  let RGB = {};
  for (let key of ["R", "G", "B"]) {
    RGB[key] = Number(data[key]);
    if (isNaN(RGB[key])) {
      RGB[key] = 0;
    } else {
      RGB[key] = Math.min(Math.max(RGB[key] / 255, 0), 1);
    }
  }
  // 计算HSB
  let max = Math.max(RGB.R, RGB.G, RGB.B);
  let min = Math.min(RGB.R, RGB.G, RGB.B);
  let HSB = {};
  if (max === RGB.R) {
    if (RGB.G >= RGB.B) {
      if (max > min) {
        HSB.H = (60 * (RGB.G - RGB.B)) / (max - min);
      } else {
        // 如果RGB相同则是灰色，不设置H避免颜色选择器H选择条变成0，使用此方法需要特意判断H的值是否为数值。
      }
    } else {
      HSB.H = (60 * (RGB.G - RGB.B)) / (max - min) + 360;
    }
  } else if (max === RGB.G) {
    HSB.H = (60 * (RGB.B - RGB.R)) / (max - min) + 120;
  } else if (max === RGB.B) {
    HSB.H = (60 * (RGB.R - RGB.G)) / (max - min) + 240;
  }
  HSB.S = (max ? 1 - min / max : 0) * 100;
  HSB.B = max * 100;
  return HSB;
};

// RGB与Lab转换通用变量
let param_13 = 1.0 / 3.0;
let param_16116 = 16.0 / 116.0;
let Xn = 0.950456;
let Yn = 1.0;
let Zn = 1.088754;
// Lab转RGB
export const Lab2RGB = data => {
  // 获取Lab
  let Lab = {};
  for (let key of ["L", "a", "b"]) {
    Lab[key] = Number(data[key]);
    if (isNaN(Lab[key])) {
      Lab[key] = 0;
    } else {
      if (key === "L") {
        Lab[key] = Math.min(Math.max(Lab[key], 0), 100);
      } else {
        Lab[key] = Math.min(Math.max(Lab[key], -128), 127);
      }
    }
  }
  let fY = (Lab.L + 16) / 116;
  let fX = fY + Lab.a / 500;
  let fZ = fY - Lab.b / 200;
  let X = (fX > 0.206893 ? Math.pow(fX, 3) : (fX - param_16116) / 7.787) * Xn;
  let Y = (fY > 0.206893 ? Math.pow(fY, 3) : (fY - param_16116) / 7.787) * Yn;
  let Z = (fZ > 0.206893 ? Math.pow(fZ, 3) : (fZ - param_16116) / 7.787) * Zn;
  return {
    R: Math.min(Math.max(3.240479 * X - 1.537150 * Y - 0.498535 * Z, 0), 1) * 255,
    G: Math.min(Math.max(-0.969256 * X + 1.875992 * Y + 0.041556 * Z, 0), 1) * 255,
    B: Math.min(Math.max(0.055648 * X - 0.204043 * Y + 1.057311 * Z, 0), 1) * 255,
  };
}

// RGB转Lab
export const RGB2Lab = data => {
  // 获取RGB
  let RGB = {};
  for (let key of ["R", "G", "B"]) {
    RGB[key] = Number(data[key]);
    if (isNaN(RGB[key])) {
      RGB[key] = 0;
    } else {
      RGB[key] = Math.min(Math.max(RGB[key] / 255, 0), 1);
    }
  }
  // 转换成XYZ
  let X = (0.412453 * RGB.R + 0.357580 * RGB.G + 0.180423 * RGB.B) / Xn;
  let Y = (0.212671 * RGB.R + 0.715160 * RGB.G + 0.072169 * RGB.B) / Yn;
  let Z = (0.019334 * RGB.R + 0.119193 * RGB.G + 0.950227 * RGB.B) / Zn;
  // 转换成Lab
  let Lab = {};
  let fX, fY, fZ;
  fY = Y > 0.008856 ? Math.pow(Y, param_13) : 7.787 * Y + param_16116;
  fX = X > 0.008856 ? Math.pow(X, param_13) : 7.787 * X + param_16116;
  fZ = Z > 0.008856 ? Math.pow(Z, param_13) : 7.787 * Z + param_16116;
  Lab.L = Math.max(116 * fY - 16, 0);
  Lab.a = 500 * (fX - fY);
  Lab.b = 200 * (fY - fZ);
  return Lab;
};

// CMYK转RGB
export const CMYK2RGB = data => {
  // 获取CMYK
  let CMYK = {};
  for (let key of ["C", "M", "Y", "K"]) {
    CMYK[key] = Number(data[key]);
    if (isNaN(CMYK[key])) {
      CMYK[key] = 0;
    } else {
      CMYK[key] = Math.min(Math.max(CMYK[key], 0), 1);
    }
  }
  // 计算RGB
  return {
    R: (1 - CMYK.C) * (1 - CMYK.K) * 255,
    G: (1 - CMYK.M) * (1 - CMYK.K) * 255,
    B: (1 - CMYK.Y) * (1 - CMYK.K) * 255,
  };
};


// RGB转CMYK
export const RGB2CMYK = data => {
  // 获取RGB
  let RGB = {};
  for (let key of ["R", "G", "B"]) {
    RGB[key] = Number(data[key]);
    if (isNaN(RGB[key])) {
      RGB[key] = 0;
    } else {
      RGB[key] = Math.min(Math.max(RGB[key] / 255, 0), 1);
    }
  }
  // 计算CMYK
  let CMYK = {
    C: (1 - RGB.R),
    M: (1 - RGB.G),
    Y: (1 - RGB.B),
  }
  CMYK.K = Math.min(CMYK.C, CMYK.M, CMYK.Y);
  if (CMYK.K === 1) {
    CMYK.C = 0;
    CMYK.M = 0;
    CMYK.Y = 0;
  } else {
    CMYK.C = (CMYK.C - CMYK.K) / (1 - CMYK.K);
    CMYK.M = (CMYK.M - CMYK.K) / (1 - CMYK.K);
    CMYK.Y = (CMYK.Y - CMYK.K) / (1 - CMYK.K);
  }
  CMYK.C *= 100;
  CMYK.M *= 100;
  CMYK.Y *= 100;
  CMYK.K *= 100;
  return CMYK;
};

// RGB转HEX(16进制)
export const RGB2HEX = data => {
  // 获取RGB
  let RGB = {};
  for (let key of ["R", "G", "B"]) {
    RGB[key] = Number(data[key]);
    if (isNaN(RGB[key])) {
      RGB[key] = 0;
    } else {
      RGB[key] = Math.min(Math.max(Math.round(RGB[key]), 0), 255);
    }
  }
  // 计算HEX(16进制)
  let R = ("0" + RGB.R.toString(16)).slice(-2);
  let G = ("0" + RGB.G.toString(16)).slice(-2);
  let B = ("0" + RGB.B.toString(16)).slice(-2);
  return R + G + B;
};

// HEX(16进制)转RGB
export const HEX2RGB = HEX => {
  // 获取HEX
  if (!HEX.slice) HEX = "000000";
  else if (HEX[0] === "#") HEX = HEX.slice(1);
  else if (/[^0-9a-f]/i.test(HEX)) return console.error("HEX格式错误，必须为16进制字符串：0-9a-f");
  if (HEX.length > 6) return console.error("HEX长度错误，必须小于或等于6个字符");
  else if (HEX.length === 3) HEX = HEX[0] + HEX[0] + HEX[1] + HEX[1] + HEX[2] + HEX[2];
  else HEX = ("000000" + HEX).slice(-6);
  // 计算RGB
  let RGB = {
    R: parseInt(HEX.slice(0, 2), 16),
    G: parseInt(HEX.slice(2, 4), 16),
    B: parseInt(HEX.slice(4, 6), 16),
  }
  return RGB;
};