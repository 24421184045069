<template>
  <canvas
    ref="musicSpectrum"
    class="music-spectrum"
    :width="spectrumWidth"
    :height="spectrumHeight"
  ></canvas>
</template>

<script>
import { musicList } from "@/assets/js/musicList";
import { spectrumColorOptions as colorOptions } from "@/assets/js/spectrumColorOptions";
export default {
  data() {
    return {
      spectrumCanvas: null,
      spectrumCtx: null,
      spectrumWidth: 0,
      spectrumHeight: 0,
      outputArray: null,
      lineData: [],
      resizeTimer: null,
    };
  },
  computed: {
    musicList: () => musicList,
    colorOptions: () => colorOptions,
    audio() {
      return this.$store.state.musicPlayer.audio;
    },
    musicIndex() {
      return this.$store.state.musicPlayer.musicIndex;
    },
    analyser() {
      return this.$store.state.musicPlayer.analyser;
    },
    colorIndex() {
      return this.$store.state.spectrumEffect.spectrumColorIndex;
    },
    colorData() {
      if (!this.colorIndex) {
        return colorOptions[musicList[this.musicIndex]?.spectrumColorIndex || 1]
          .colorData;
      }
      return (
        colorOptions[this.colorIndex].colorData ||
        this.$store.state.spectrumEffect.spectrumColorData
      );
    },
    lineColor() {
      let color = {};
      let colorData = JSON.parse(JSON.stringify(this.colorData)).sort(
        (a, b) => a.position - b.position
      );
      colorData.forEach((item) => {
        while (color[item.position]) {
          item.position += 0.0001;
        }
        color[item.position] = item.color;
      });
      if (!color[0]) color[0] = colorData[0].color;
      if (!color[1]) color[1] = colorData[colorData.length - 1].color;
      return color;
    },
    lineLength() {
      return this.$rs(this.$store.state.spectrumEffect.spectrumLineLength);
    },
    lineNum() {
      return this.$store.state.spectrumEffect.spectrumLineNum;
    },
    numAccording() {
      return this.$store.state.spectrumEffect.spectrumNumAccording;
    },
    lineWidth() {
      return this.$rs(this.$store.state.spectrumEffect.spectrumLineWidth);
    },
    lineGap() {
      return this.$rs(this.$store.state.spectrumEffect.spectrumLineGap);
    },
  },
  methods: {
    resize() {
      this.spectrumWidth = document.body.clientWidth;
      this.spectrumHeight = document.body.clientHeight;
    },
    createSpectrum() {
      this.resize();
      this.outputArray = new Uint8Array(520);
      this.$animationFrameFuncAdd(this.draw);
    },
    draw() {
      if (!this.analyser) return;
      // 清除画布
      this.spectrumCtx.clearRect(0, 0, this.spectrumWidth, this.spectrumHeight);
      this.analyser.getByteFrequencyData(this.outputArray); // 获取频域数据
      let lineNum, spectrumWidth;
      if (this.numAccording) {
        lineNum = this.lineNum;
        spectrumWidth = this.spectrumWidth;
      } else if(!this.lineWidth) {
        lineNum = 0;
        spectrumWidth = this.spectrumWidth;
      } else {
        if (window.innerWidth >= 768) {
          lineNum = Math.ceil(
            (window.innerWidth - this.lineGap) /
              2 /
              (this.lineWidth + this.lineGap)
          );
          spectrumWidth = lineNum * (this.lineWidth + this.lineGap) * 2;
        } else {
          lineNum = Math.ceil(
            (window.innerWidth - this.lineGap) / (this.lineWidth + this.lineGap)
          );
          spectrumWidth = lineNum * (this.lineWidth + this.lineGap);
        }
      }
      // 绘制线条
      for (let i = 0; i < lineNum; i++) {
        let length = 0; // 获取数据
        let start = (512 * i) / lineNum;
        let end = (512 * (i + 1)) / lineNum;
        for (let j = Math.floor(start); j < Math.ceil(end); j++) {
          if (j < start) {
            length += this.outputArray[j + 8] * (j + 1 - start);
          } else {
            length += this.outputArray[j + 8] * Math.min(end - j, 1);
          }
        }
        length = length / (end - start) / 256; // 获取数据
        if (this.lineData[i] > length) {
          this.lineData[i] *= 0.95;
        } else {
          this.lineData[i] = length;
        }
        this.spectrumCtx.lineWidth = this.lineWidth; // 线条宽度
        // 计算颜色
        let color;
        if (
          typeof this.lineColor === "string" ||
          Array.isArray(this.lineColor)
        ) {
          color = this.lineColor;
        } else if (this.lineColor[this.lineData[i]]) {
          color = this.lineColor[this.lineData[i]];
        } else {
          let node = [];
          for (const key in this.lineColor) {
            if (key >= 0 && key <= 1) {
              node.push(key);
            }
          }
          let colorStart = Math.max(
            ...node.filter((item) => item < this.lineData[i]),
            0
          );
          let colorEnd = Math.min(
            ...node.filter((item) => item > this.lineData[i]),
            1
          );
          let proportion =
            (this.lineData[i] - colorStart) / (colorEnd - colorStart);
          color = [0, 1, 2, 3].map((item) => {
            let startColor = this.lineColor[colorStart][item];
            let endColor = this.lineColor[colorEnd][item];
            return startColor + (endColor - startColor) * proportion;
          });
        }
        this.spectrumCtx.strokeStyle = `rgba(${color})`;
        // 绘制线条
        this.spectrumCtx.beginPath();
        let y = this.spectrumHeight * (1 - this.lineLength * this.lineData[i]);
        if (window.innerWidth >= 768) {
          let dx = (spectrumWidth / 2) * (1 - (i + 0.5) / lineNum);
          // 左半部分
          this.spectrumCtx.moveTo(
            this.spectrumWidth / 2 - dx,
            this.spectrumHeight
          );
          this.spectrumCtx.lineTo(this.spectrumWidth / 2 - dx, y);
          this.spectrumCtx.stroke();
          // 右半部分
          this.spectrumCtx.beginPath();
          this.spectrumCtx.moveTo(
            this.spectrumWidth / 2 + dx,
            this.spectrumHeight
          );
          this.spectrumCtx.lineTo(this.spectrumWidth / 2 + dx, y);
        } else {
          let x =
            spectrumWidth * ((i + 0.5) / lineNum) -
            (spectrumWidth - this.spectrumWidth);
          // 移动端只绘制一半
          this.spectrumCtx.moveTo(x, this.spectrumHeight);
          this.spectrumCtx.lineTo(x, y);
        }
        this.spectrumCtx.stroke();
      }
    },
  },
  mounted() {
    this.spectrumCanvas = this.$refs.musicSpectrum;
    if (this.spectrumCanvas.getContext) {
      this.spectrumCtx = this.spectrumCanvas.getContext("2d");
      this.createSpectrum();
      this.$windowResizeFuncAdd(this.resize);
    }
  },
  beforeUnmount() {
    this.$windowResizeFuncRemove(this.resize);
    this.$animationFrameFuncRemove(this.draw);
  },
};
</script>

<style lang="scss" scoped>
.music-spectrum {
  width: 100%;
  height: 100%;
}
</style>