<template>
  <div id="cover" class="no-animate">
    <MusicSpectrum />
  </div>
</template>

<script>
import MusicSpectrum from "./cover/MusicSpectrum";
export default {
  components: {
    MusicSpectrum,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
#cover {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  overflow: hidden;
  pointer-events: none;
}
</style>