export const spectrumColorOptions = [{
    name: "自适应",
  },
  {
    name: "梦幻紫",
    colorData: [{
        position: 0,
        color: [51, 204, 255, 0.2],
      },
      {
        position: 0.4,
        color: [51, 51, 255, 0.6],
      },
      {
        position: 0.8,
        color: [204, 51, 255, 1],
      },
      {
        position: 1,
        color: [255, 51, 204, 1],
      },
    ],
  },
  {
    name: "幽静灰",
    colorData: [{
        position: 0,
        color: [32, 64, 64, 0.2],
      },
      {
        position: 0.3,
        color: [64, 128, 128, 0.4],
      },
      {
        position: 0.7,
        color: [128, 160, 160, 1],
      },
      {
        position: 1,
        color: [192, 224, 224, 1],
      },
    ],
  },
  {
    name: "极光绿",
    colorData: [{
        position: 0,
        color: [0, 102, 0, 0.2],
      },
      {
        position: 0.2,
        color: [51, 153, 0, 0.4],
      },
      {
        position: 0.6,
        color: [153, 204, 51, 1],
      },
      {
        position: 1,
        color: [51, 204, 0, 1],
      },
    ],
  },
  {
    name: "落日橙",
    colorData: [{
        position: 0,
        color: [51, 102, 51, 0.2],
      },
      {
        position: 0.4,
        color: [153, 102, 0, 0.6],
      },
      {
        position: 0.6,
        color: [255, 153, 51, 1],
      },
      {
        position: 1,
        color: [255, 255, 128, 1],
      },
    ],
  },
  {
    name: "激情红",
    colorData: [{
        position: 0,
        color: [64, 96, 96, 0.2],
      },
      {
        position: 0.4,
        color: [192, 64, 0, 0.6],
      },
      {
        position: 0.8,
        color: [255, 0, 0, 1],
      },
      {
        position: 1,
        color: [255, 128, 128, 1],
      },
    ],
  },
  {
    name: "暖阳黄",
    colorData: [{
        position: 0,
        color: [51, 102, 0, 0.2],
      },
      {
        position: 0.2,
        color: [153, 153, 0, 0.6],
      },
      {
        position: 0.6,
        color: [255, 255, 64, 1],
      },
      {
        position: 1,
        color: [255, 255, 192, 1],
      },
    ],
  },
  {
    name: "自定义",
  },
];