<template>
  <div id="footer">
    <div class="container">
      <a href="https://www.pizzalin.com/">www.pizzalin.com</a
      >&nbsp;&nbsp;&nbsp;&nbsp;<a
        href="https://beian.miit.gov.cn/"
        target="_blank"
        >备案号：粤ICP备2020133183号-2</a
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
#footer {
  width: 100%;
  color: #666;
  text-align: center;
  background: rgba(var(--color-bg-dark3), 1);
  @media (min-width: 768px) {
    height: 100px;
    line-height: 100px;
    border-top: 1px solid #666;
  }
  @media (max-width: 767px) {
    height: 15vw;
    line-height: 15vw;
    border-top: 0.15px solid #666;
  }
  a {
    color: #666;
  }
}
</style>